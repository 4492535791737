import React, { useState } from 'react';
import { Card, Button, Modal, Form, Input, Select, Space, Alert, Row, Col } from 'antd';
import OrganisationActions from '../../actions/organisationActions';
const OrganisationManagement = ({ organisations = [], setLoading }) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const formattedOrgs = organisations.map(org => ({
    value: org.organisation_id,
    label: org.name,
    ...org,
  }));

  const handleCreate = (values) => {
    setLoading(true);

    const newOrg = {
      name: values.name,
      description: values.description,
      status: values.status,
      registration_status: values.registration_status
    }

    // Create estate for new organisation
    const newEstate = {
      name: values.name,
      description: values.description,
      status: values.status,
    }

    OrganisationActions.createOrganisation(newOrg)
      .then((response) => {
        OrganisationActions.createEstate({
          ...newEstate,
          organisation_id: response.organisation_id
        })
      })
      .then(() => {
        setCreateModalVisible(false);
        createForm.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = (values) => {
    setLoading(true);

    const updatedValues = {
      "organisation_id": selectedOrg?.organisation_id,
      "name": values.name,
      "description": values.description,
      "status": values.status,
      "registration_status": values.registration_status
    }

    OrganisationActions.updateOrganisation(updatedValues)
      .then(() => {
        setUpdateModalVisible(false);
        updateForm.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);

    OrganisationActions.deleteOrganisation(selectedOrg.organisation_id)
      .then(() => {
        setDeleteModalVisible(false);
        setSelectedOrg(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOrgSelect = (value, formInstance) => {
    const selectedOption = formattedOrgs.find(org => org.value === value);
    setSelectedOrg(selectedOption);
    formInstance.setFieldsValue({
      name: selectedOption.name,
      description: selectedOption.description,
      status: selectedOption.status,
      registration_status: selectedOption.registration_status
    });
  };

  const handleShowModal = (modalSetter) => {
    resetForm(modalSetter);
    setSelectedOrg(null);
    modalSetter(true);
  };

  const handleModalClose = (modalSetter) => {
    resetForm(modalSetter);
    setSelectedOrg(null);
    modalSetter(false);
  };

  const resetForm = (formInstance) => {
    if (formInstance === setUpdateModalVisible) {
      updateForm.resetFields();
    } else if (formInstance === setDeleteModalVisible) {
      deleteForm.resetFields();
    } else {
      createForm.resetFields();
    }
  };

  const registrationStatusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'TRIAL', label: 'Trial' },
    { value: 'TRIAL_EXPIRED', label: 'Trial Expired' },
    { value: 'EXPIRED', label: 'Expired' },
    { value: 'OVERDUE', label: 'Overdue' }
  ];

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'disabled', label: 'Disabled' }
  ];

  const CreateForm = () => (
    <Form
      form={createForm}
      layout="vertical"
      initialValues={{
        name: '',
        description: '',
        status: 'active',
        registration_status: 'ACTIVE'
      }}
    >
      <Form.Item
        name="name"
        label="Organisation Name"
        rules={[{ required: true, message: 'Please input organisation name' }]}
      >
        <Input placeholder="Enter organisation name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please input description' }]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select status' }]}
        >
          <Select
            style={{ width: 200 }}
            options={statusOptions}
            placeholder="Select status"
          />
        </Form.Item>

        <Form.Item
          name="registration_status"
          label="Registration Status"
          rules={[{ required: true, message: 'Please select registration status' }]}
        >
          <Select
            style={{ width: 200 }}
            options={registrationStatusOptions}
            placeholder="Select registration status"
          />
        </Form.Item>
      </Space>
    </Form>
  );

  const UpdateForm = () => {

    return (
      <Form
        form={updateForm}
        layout="vertical"
        values={selectedOrg}
      >
        <Form.Item
          name="organisation"
          label="Select Organisation"
          rules={[{ required: true, message: 'Please select an organisation' }]}
        >
          <Select
            showSearch
            options={formattedOrgs}
            value={selectedOrg?.organisation_id}
            placeholder="Select organisation"
            optionFilterProp="label"
            onChange={(value) => handleOrgSelect(value, updateForm)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Organisation Name"
          rules={[{ required: true, message: 'Please input organisation name' }]}
        >
          <Input placeholder="Enter organisation name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please input description' }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>

        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please select status' }]}
          >
            <Select
              style={{ width: 200 }}
              options={statusOptions}
              placeholder="Select status"
            />
          </Form.Item>

          <Form.Item
            name="registration_status"
            label="Registration Status"
            rules={[{ required: true, message: 'Please select registration status' }]}
          >
            <Select
              style={{ width: 200 }}
              options={registrationStatusOptions}
              placeholder="Select registration status"
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const DeleteForm = () => {

    return (
      <Form
        form={deleteForm}
        layout="vertical"
      >
        <Form.Item
          name="organisation"
          label="Select Organisation"
          rules={[{ required: true, message: 'Please select an organisation' }]}
        >
          <Select
            showSearch
            options={formattedOrgs}
            placeholder="Select organisation"
            optionFilterProp="label"
            onChange={(value) => handleOrgSelect(value, deleteForm)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        {selectedOrg && (
          <Alert
            message="Warning"
            description={`Are you sure you want to delete ${selectedOrg.label}?`}
            type="warning"
            showIcon
          />
        )}
      </Form>
    );
  };

  return (
    <Card title="Organisation Management">
      <Row gutter={[15, 15]} style={{ marginBottom: '20px' }}>
        <Col lg={8} md={8} xs={24}>
          <Button
            type="primary"
            style={{
              backgroundColor: 'green',
              width: '100%',
              height: '50px'
            }}
            onClick={() => handleShowModal(setCreateModalVisible)}
          >
            Create Organisation
          </Button>
        </Col>
        <Col lg={8} md={8} xs={24}>
          <Button
            type="primary"
            style={{
              width: '100%',
              height: '50px'
            }}
            onClick={() => handleShowModal(setUpdateModalVisible)}
          >
            Update Organisation
          </Button>
        </Col>
        <Col lg={8} md={8} xs={24}>
          <Button
            type="primary"
            danger
            style={{
              width: '100%',
              height: '50px'
            }}
            onClick={() => handleShowModal(setDeleteModalVisible)}
          >
            Delete Organisation
          </Button>
        </Col>
      </Row>

      <Modal
        title="Create Organisation"
        open={createModalVisible}
        onOk={() => createForm.validateFields().then(handleCreate)}
        onClose={() => handleModalClose(setCreateModalVisible)}
        onCancel={() => handleModalClose(setCreateModalVisible)}
      >
        <CreateForm />
      </Modal>

      <Modal
        title="Update Organisation"
        open={updateModalVisible}
        onOk={() => updateForm.validateFields().then(handleUpdate)}
        onClose={() => handleModalClose(setUpdateModalVisible)}
        onCancel={() => handleModalClose(setUpdateModalVisible)}
      >
        <UpdateForm />
      </Modal>

      <Modal
        title="Delete Organisation"
        open={deleteModalVisible}
        onOk={handleDelete}
        onClose={() => handleModalClose(setDeleteModalVisible)}
        onCancel={() => handleModalClose(setDeleteModalVisible)}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <DeleteForm />
      </Modal>
    </Card>
  );
};

export default OrganisationManagement; 