import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import GeneralUtils from '../../../../utils/GeneralUtils';
import percentIcon from '../../../../assets/icons/percent.png';
import numbersIcon from '../../../../assets/icons/123.png';

// Import the annotations module
import Annotations from 'highcharts/modules/annotations';

// Initialize the annotations module
Annotations(Highcharts);

const CHART_HEIGHT = '100%';

const GlidePathChart = ({ data = {}, loading, error, selectedTargetYear, selectedScope, interimTargets = [] }) => {
    const [series, setSeries] = useState([]);
    const [annotations, setAnnotations] = useState([]);
    const [showPercentage, setShowPercentage] = useState(false);

    useEffect(() => {
        if (!data) return;
        const scopeData = data[selectedScope];

        if (!scopeData) {
            setSeries([])
            return
        }

        const newSeries = [];
        const annotationsArray = [];
        const historicalAndForecast = scopeData.historical_and_forecast.filter(item => item.year <= selectedTargetYear);
        const target = scopeData.target.filter(item => item.year <= selectedTargetYear);
        if (!historicalAndForecast) return;

        const currentYear = new Date().getFullYear();
        const currentYearIndex = historicalAndForecast.findIndex(item => item.year === currentYear);

        if (data) {
            newSeries.push({
                name: 'Past Performance',
                data: historicalAndForecast.slice(0, currentYearIndex + 1).map(item => [moment(`${item.year}-01-01`).valueOf(), Math.round(item.value / 1000)]),
                color: '#7cb5ec',
                dashStyle: 'Solid'
            });

            newSeries.push({
                name: 'Forecast',
                data: historicalAndForecast.slice(currentYearIndex).map(item => [moment(`${item.year}-01-01`).valueOf(), Math.round(item.value / 1000)]),
                color: '#ff0000',
                dashStyle: 'DotDash'
            });

            newSeries.push({
                name: 'Target',
                data: target.map(item => [moment(`${item.year}-01-01`).valueOf(), Math.round(item.value / 1000)]),
                color: '#00ff00',
                dashStyle: 'Solid',
                dataLabels: {
                    enabled: false
                }
            });

            interimTargets.forEach(item => {
                const matchingDatapoint = target.find(el => el.year === item.target_year);
                if (!matchingDatapoint) return;

                const baselineValue = historicalAndForecast[0].value;
                const formattedTargetValue = showPercentage ?
                    GeneralUtils.getFormattedNumberWithUnit((matchingDatapoint.value / baselineValue) * 100, '%', 0) :
                    GeneralUtils.getFormattedNumberWithUnit(matchingDatapoint.value / 1000 , ' tCO₂e', 0);

                const name = `${matchingDatapoint.year} Target: ${formattedTargetValue}`

                annotationsArray.push({
                    point: {
                        x: moment(`${matchingDatapoint.year}-01-01`).valueOf(),
                        y: Math.round(matchingDatapoint.value / 1000),
                        percentage: (matchingDatapoint.value / baselineValue) * 100,
                        xAxis: 0,
                        yAxis: 0
                    },
                    text: name,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    borderRadius: 10,
                    style: {
                        color: '#FFFFFF',
                        fontSize: '12px',
                        textAlign: 'center'
                    }
                });
            })

            if (showPercentage) {
                const firstPastPerformanceValue = newSeries[0].data[0][1];
                newSeries.forEach(series => {
                    series.data = series.data.map(point => [point[0], (point[1] / firstPastPerformanceValue) * 100]);
                });

                annotationsArray.forEach((annotation) => {
                    annotation.point.y = annotation.point.percentage
                });
            }
        }

        setSeries(newSeries);
        setAnnotations(annotationsArray);
    }, [data, selectedTargetYear, showPercentage, selectedScope, interimTargets]);

    const getGlidePathChart = () => {
        const chartOptions = {
            chart: {
                type: 'spline',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                minPadding: 0,
                labels: {
                    format: '{value:%Y}',
                    style: {
                        fontSize: '10px'
                    }
                }
            },
            yAxis: {
                title: {
                    text: showPercentage ? '% of Baseline Emissions' : 'Emissions (tCO₂e)'
                },
                min: 0,
                max: showPercentage ? 100 : null,
                labels: {
                    format: showPercentage ? '{value}%' : '{value}'
                }
            },
            tooltip: {
                shared: true,
                xDateFormat: '%Y',
                pointFormat: showPercentage ? '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.1f}%</b><br/>' : '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f} tCO₂e</b><br/>'
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: false,
                    },
                    enableMouseTracking: true,
                    marker: {
                        enabled: false
                    },
                    lineWidth: 4
                }
            },
            series: series,
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "downloadPNG",
                            "downloadPDF",
                            "downloadSVG",
                            "downloadCSV",
                            "downloadXLS",
                        ]
                    },
                    percentageToggleButton: {
                        titleKey: 'togglePercentage',
                        symbol: `url(${showPercentage ? numbersIcon : percentIcon})`,
                        symbolX: 20,
                        symbolY: 18.5,
                        onclick: function () {
                            setAnnotations([])
                            setShowPercentage(!showPercentage);
                        },
                    },
                }
            },
            annotations: [{
                labels: annotations,
                draggable: false,
                labelOptions: {
                    y: -20
                }
            }]
        };

        return <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: CHART_HEIGHT } }}
        />
    }

    if (series.length === 0 && loading) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></div>
    }

    if (error) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography.Title level={4}>No data available</Typography.Title>
        <Typography.Text>Please make sure your baseline year has consumption data.</Typography.Text>
    </div>
    }

    if (data && data[selectedScope] === null && series.length === 0) {
        return (<div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={4}>No data available for selected scope.</Typography.Title>
        </div>)
    }

    if (series.length === 0) {
        return null
    }

    return getGlidePathChart()
};

export default GlidePathChart;
