import React from 'react';
import { IFrameWrapper } from "../IFrameWrapper";
import UrlManager from "../../../../utils/UrlManager.js";

const ExamplePage = () => {
  const URL = UrlManager.getNewUIUrl() + "/example";
  return <IFrameWrapper url={URL} />;
};

export { ExamplePage };
