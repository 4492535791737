import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing doorways. This class interfaces with the API
 * to retrieve, create, update, and delete doorway data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const doorwayActions = new DoorwayActions();
 * doorwayActions.getBuildingDoorways('building123');
 */
class DoorwayActions {
  /**
   * Fetches doorways for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingDoorways(buildingId) {
    return GeneralUtils.get({
      url: `doorway/building/${buildingId}`,
      actionType: ActionTypes.DOORWAYS_FETCHED,
      failToast: "Unable to get building doorways, please try again",
    });
  }

  /**
   * Saves a new doorway to the API and dispatches an action to store the new doorway data.
   * Optionally saves associated links if provided.
   *
   * @param {Object} doorway - The doorway object to be saved.
   * @param {Object[]} [links=[]] - The array of links associated with the doorway.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  async saveDoorway(doorway, links = []) {
    const response = await GeneralUtils.post({
      url: "doorway",
      object: doorway,
      actionType: ActionTypes.DOORWAY_SAVED,
      successToast: `Doorway ${doorway.name} saved successfully`,
      failToast: `Unable to save doorway ${doorway.name}, please try again`,
    });
    if (response && links.length > 0) {
      links.forEach((link) => this.saveLink({
        doorwayId: response.doorwayId,
        zoneId: link.zoneId,
        sensorPlacement: link.sensorPlacement,
      }));
    }
    return response;
  }

  /**
   * Saves a new link to the API and dispatches an action to store the new link data.
   *
   * @param {Object} link - The link object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveLink(link) {
    return GeneralUtils.post({
      url: "link",
      object: link,
      actionType: ActionTypes.LINK_SAVED,
      successToast: "Link saved successfully",
      failToast: "Unable to save link, please try again",
    });
  }

  /**
   * Updates an existing doorway in the API and dispatches an action to store the updated data.
   * Optionally saves new associated links if provided.
   *
   * @param {Object} doorway - The doorway object to be updated.
   * @param {Object[]} [links=[]] - The array of links associated with the doorway.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  async updateDoorway(doorway, links = []) {
    const response = await GeneralUtils.put({
      url: "doorway",
      object: doorway,
      actionType: ActionTypes.DOORWAY_UPDATED,
      successToast: `Doorway ${doorway.name} updated successfully`,
      failToast: `Unable to update doorway ${doorway.name}, please try again`,
    });
    if (response && links.length > 0) {
      links.forEach((link) => {
        if (!link.linkId) {
          this.saveLink({
            doorwayId: response.doorwayId,
            zoneId: link.zoneId,
            sensorPlacement: link.sensorPlacement,
          });
        }
      });
    }
    return response;
  }

  /**
   * Deletes a specific doorway by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} doorwayId - The unique identifier of the doorway to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteDoorway(doorwayId) {
    return GeneralUtils.delete({
      url: `doorway/${doorwayId}`,
      actionType: ActionTypes.DOORWAY_DELETED,
      successToast: `Doorway ${doorwayId} deleted successfully`,
      failToast: "Unable to delete doorway, please try again",
    });
  }
}

const doorwayActions = new DoorwayActions();
export default doorwayActions;
