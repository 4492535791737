import GeneralUtils from "./GeneralUtils";

class ComponentUtils {

    /**
     * Generates an array of objects containing `value` and `label` fields, sorted by label.
     *
     * @param {Array} values - An array of input values to be converted into value/label pairs.
     * @param {Function} displayTextFunction - A function that takes a value and returns its corresponding label.
     *                                         If the function returns `null`, a default conversion is applied.
     *
     * @returns {Array} - An array of objects, each containing:
     *   - `value`: The original input value.
     *   - `label`: The corresponding human-readable label, either from `displayTextFunction` or the default.
     *              The objects are sorted alphabetically by the `label` field.
     *
     * Example Input/Output:
     * Input:
     *   values = ["NATURAL_GAS", "ELECTRIC", "PROPANE", "SOLAR"];
     *   displayTextFunction = (value) => value === "NATURAL_GAS" ? "Natural Gas" : null;
     * Output:
     *   [
     *     { value: "ELECTRIC", label: "Electric" },
     *     { value: "NATURAL_GAS", label: "Natural Gas" },
     *     { value: "PROPANE", label: "Propane" },
     *     { value: "SOLAR", label: "Solar" }
     *   ]
     */
    generateLabelsForValues(values, displayTextFunction) {
        const valuesWithLabels = values.map((value) => {
            const label = displayTextFunction 
            ? displayTextFunction(value) ?? GeneralUtils.defaultValueToDisplayText(value)
            : GeneralUtils.defaultValueToDisplayText(value);
        return { value, label };
        });

        const sortedValues = valuesWithLabels.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        return sortedValues; 
    }

    /**
     * Generates an array of objects containing `value` and `label` fields, 
     * where each field can be accessed from nested properties using dot notation.
     *
     * @param {Array} values - An array of objects from which to extract `value` and `label`.
     * @param {Function} valueFunction - A function that takes an object and returns the value to be used.
     * @param {Function} labelFunction - A function that takes an object and returns the label to be used.
     *
     * @returns {Array} - An array of objects, each containing:
     *   - `value`: The value extracted from the object using the `valueFunction`.
     *   - `label`: The label extracted from the object using the `labelFunction`.
     *
     * Example Input/Output:
     * Input:
     *   values = [
     *     { id: 1, details: { name: "Item 1" } },
     *     { id: 2, details: { name: "Item 2" } }
     *   ];
     *   valueFunction = (value) => value.id;
     *   labelFunction = (value) => value.details.name;
     * Output:
     *   [
     *     { value: 1, label: "Item 1" },
     *     { value: 2, label: "Item 2" }
     *   ]
     */
    generateSelectOptions(values, valueFunction, labelFunction) {
        const options = values.map(value => ({
            value: valueFunction(value),
            label: labelFunction(value)
        }));

        // Sort options by label
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }

}

const componentUtils = new ComponentUtils();
export default componentUtils;