class NetZeroUtils {

    allMonthsNull() {
        return Array(12).fill(null);
    }

    sum(values) {
        return values.reduce((acc, val) => acc + (val || 0), 0)
    }

    isAllNull(months) {
        return months.every(val => val == null)
    }

    /**
     * Distributes a `total` (integer or float, positive or negative) 
     * evenly into 12 values whose sum will match the original `total`.
     *
     * @param {number} total       The total amount to distribute (can be negative).
     * @param {number} [decimals]  How many decimals to keep for float distribution 
     *                             (only applies if `total` is not an integer).
     * @returns {number[]}         An array of 12 numbers whose sum is `total`.
     */
    distributeOverMonths(total, decimals = 2) {
        if (total == null) {
            return this.allMonthsNull();
        }

        // Determine sign and take absolute value
        const sign = total >= 0 ? 1 : -1;
        const absoluteTotal = Math.abs(total);

        // If absoluteTotal is effectively an integer, do integer-based distribution
        // (Note: we check "Number.isInteger(absoluteTotal)" 
        //  because total might be -100, whose abs is 100.)
        if (Number.isInteger(absoluteTotal)) {
            const base = Math.floor(absoluteTotal / 12);
            const remainder = absoluteTotal % 12;

            const result = Array(12).fill(base);
            for (let i = 0; i < remainder; i++) {
                result[i] += 1;
            }

            // Reapply sign to each value
            return result.map(val => sign * val);
        } else {
            // Float distribution
            const share = absoluteTotal / 12;
            const result = [];
            let partialSum = 0;

            // Push 11 rounded shares
            for (let i = 0; i < 11; i++) {
                const rounded = parseFloat(share.toFixed(decimals));
                result.push(rounded);
                partialSum += rounded;
            }

            // For the 12th, ensure we get the exact remainder 
            // so the sum matches absoluteTotal exactly
            const final = parseFloat((absoluteTotal - partialSum).toFixed(decimals));
            result.push(final);

            // Reapply sign to each value
            return result.map(val => sign * val);
        }
    }

}

const netZeroUtils = new NetZeroUtils();
export default netZeroUtils;
