import React, { useState, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import percentIcon from '../../../../assets/icons/percent.png';
import numbersIcon from '../../../../assets/icons/123.png';

const CHART_HEIGHT = '100%';
const TARGET_COLOR = '#CCC';
const REDUCTION_COLOR = '#1ca289';
const INCREASE_COLOR = '#ff6b6b';
const SUPPLY_SIDE_REDUCTION_COLOR = 'teal';
const SUPPLY_SIDE_INCREASE_COLOR = INCREASE_COLOR;
const GAP_REDUCTION_COLOR = REDUCTION_COLOR + '30';
const GAP_INCREASE_COLOR = INCREASE_COLOR + '30';

const WaterfallChart = ({ data = [], loading, error, columnClickHandler, editTargetHandler, selectedTargetYear, selectedScope }) => {
    const [series, setSeries] = useState([]);
    const [showPercentage, setShowPercentage] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!data) return;
        const scopeData = data[selectedScope];
        if (!scopeData) {
            setSeries([])
            return;
        }

        const series = [];
        let gapValue = 0;

        let base = null;
        let reduction = null;
        let latest = null;
        let actions = [];
        let supply_side = null;
        let difference = null;
        let target = null;

        if (scopeData?.base) {
            const baseSeries = {
                name: `Baseline ${scopeData.base.year} Emissions`,
                year: scopeData.base.year,
                y: scopeData.base.value / 1000,
                color: TARGET_COLOR
            }

            base = baseSeries
        }


        // If latest data is available, add it to the series
        if (scopeData?.latest) {
            const latestSeries = {
                name: scopeData.latest.year + ' Emissions',
                year: scopeData.latest.year,
                y: scopeData.latest.value / 1000,
                color: TARGET_COLOR,
                isIntermediateSum: true
            }

            latest = latestSeries
        }

        if (base && latest) {
            const reductionValue = scopeData.latest.value - scopeData.base.value;
            const reductionSeries = {
                name: 'Change from Baseline',
                y: reductionValue / 1000,
                color: reductionValue < 0 ? REDUCTION_COLOR : INCREASE_COLOR
            }

            reduction = reductionSeries
        }

        // If actions are available, add them to the series
        if (scopeData?.actions.length) {

            const sortedActions = scopeData.actions
                // filter actions by target year
                .filter(a => a.impact_year <= selectedTargetYear)
                .sort((a, b) => a.impact_year - b.impact_year)
                // add actions to the series
                .map(a => {
                    const value = a.values.find(e => e.year === selectedTargetYear).value

                    return {
                        name: a.description,
                        year: a.impact_year,
                        y: value / 1000,
                        color: value < 0 ? REDUCTION_COLOR : INCREASE_COLOR,
                        events: {
                            click: () => columnClickHandler(a.id)
                        }
                    }
                })

            actions = sortedActions
        }

        // If targets are available, add selected target to the series
        if (scopeData?.targets.length) {
            const selectedTarget = scopeData.targets.find(t => t.year === selectedTargetYear);
            const targetSeries = {
                name: selectedTarget.year + ' Target',
                year: selectedTarget.year,
                y: selectedTarget.value / 1000,
                color: TARGET_COLOR,
                isSum: true,
                events: {
                    click: () => editTargetHandler()
                },
            }

            gapValue = selectedTarget.gap / 1000
            target = targetSeries
        }

        if (scopeData?.supply_side?.length) {
            const selectedSupplySide = scopeData.supply_side.find(t => t.year === selectedTargetYear);
            const supplySideSeries = {
                name: 'Supply Side Reductions',
                year: selectedSupplySide.year,
                y: selectedSupplySide.value / 1000,
                color: selectedSupplySide.value < 0 ? SUPPLY_SIDE_REDUCTION_COLOR : SUPPLY_SIDE_INCREASE_COLOR,
            }

            supply_side = supplySideSeries
        }

        if (target && base) {
            const differenceValue = gapValue
            const differenceSeries = {
                name: differenceValue > 0 ? 'Surplus vs Target' : 'Deficit vs Target',
                year: selectedTargetYear,
                y: differenceValue,
                color: differenceValue > 0 ? GAP_REDUCTION_COLOR : GAP_INCREASE_COLOR,
                borderColor: differenceValue > 0 ? REDUCTION_COLOR : INCREASE_COLOR,
                borderWidth: 3
            }
            difference = differenceSeries
        }

        if (base) series.push(base);
        if (reduction) series.push(reduction)
        if (latest) series.push(latest);
        if (actions.length) series.push(...actions);
        if (supply_side) series.push(supply_side)
        if (difference) series.push(difference);
        if (target) series.push(target);

        // Convert series to percentage
        if (showPercentage && series.length > 0) {
            const firstValue = series[0].y;
            series.forEach(item => {
                item.y = (item.y / firstValue) * 100;
            });
        }

        setSeries(series)

    }, [data, columnClickHandler, editTargetHandler, selectedTargetYear, showPercentage, selectedScope]);

    useEffect(() => {
        const chart = chartRef?.current?.chart;

        if (chart) {
            chart.series.forEach(series => {
                series.points.forEach(point => {
                    if (point.events?.click) {
                        point.graphic.attr({ cursor: 'pointer' });
                    }
                });
            });
        }
    }, [series]);

    const calculateMaxY = () => {
        if (!data || !data[selectedScope]) return null;

        const scopeData = data[selectedScope];

        const base = scopeData?.base?.value || 0;
        const latest = scopeData?.latest?.value || 0;
        if (showPercentage) {
            return base >= latest ? 100 : null;
        }
        const maxY = Math.max(base, latest);
        return (maxY / 1000) * 1.05;
    }

    const getWaterfallChart = () => {

        const chartOptions = {
            chart: {
                type: 'waterfall',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontSize: '10px'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: showPercentage ? '% of Baseline Emissions' : 'tCO₂e'
                },
                max: calculateMaxY(),
            },
            tooltip: {
                pointFormat: showPercentage ? '{point.y:,.1f}%' : '{point.y:,.0f} tCO₂e'
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    pointPadding: 0.05,
                    groupPadding: 0,
                    borderWidth: 0,
                    borderRadius: 5,
                }
            },
            series: [{
                name: 'Emissions',
                data: series,
                dataLabels: {
                    enabled: true,
                    format: showPercentage ? '{y:,.1f}%' : '{y:,.0f}',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }
                },
            }],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "downloadPNG",
                            "downloadPDF",
                            "downloadSVG",
                            "downloadCSV",
                            "downloadXLS",
                        ]
                    },
                    percentageToggleButton: {
                        titleKey: 'togglePercentage',
                        symbol: `url(${showPercentage ? numbersIcon : percentIcon})`,
                        symbolX: 20,
                        symbolY: 18.5,
                        onclick: function () {
                            setShowPercentage(!showPercentage);
                        },
                    },
                }
            }
        };

        return <HighchartsReact
            highcharts={Highcharts}
            ref={chartRef}
            options={chartOptions}
            containerProps={{ style: { height: CHART_HEIGHT } }}
        />
    } 

    // Loading
    if (series.length === 0 && loading) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></div>
    }

    // Error
    if (error) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={4}>No data available</Typography.Title>
            <Typography.Text>Please make sure your baseline year has consumption data and there are actions available.</Typography.Text>
        </div>
    }

    // No data available for selected scope
    if (data && data[selectedScope] === null && series.length === 0) {
        return (<div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={4}>No data available for selected scope.</Typography.Title>
        </div>)
    }

    // No series
    if (series.length === 0) {
        return null
    }

    return getWaterfallChart()
};

export default WaterfallChart;
