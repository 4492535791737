import React from "react";
import _ from "lodash";

//components
import { DocumentTitle } from "../../components/DocumentTitle";
import { LogoSpinner } from "../../components/LogoSpinner";
import { PageTitle } from "../../components/PageTitle";
import { AnalysisCard } from "../../components/AnalysisCard";

//stores
import UserStore from "../../stores/userStore";

import { Row, Col } from 'antd';

class Analysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    title: "Net Zero Planning",
                    description:
                        "Assess your carbon footprint and help you select actionable strategies to achieve net-zero emissions, driving sustainability and efficiency.",
                    imgsrc: "/img/analysis-thumbnails/net-zero.png",
                    type: "energy",
                    disabled: false,
                    link: "/net-zero-planning",
                    allowed_ids: [348, 310]
                },
                {
                    title: "Out-of-Hours Energy Analysis",
                    description:
                        "Identify opportunities to reduce energy and emissions by comparing energy consumed outside of normal building occupied hours to that consumed inside hours, on a prorata basis. ",
                    imgsrc: "/img/analysis-thumbnails/consumption-analysis.png",
                    type: "energy",
                    disabled: false,
                    link: "/consumption-analysis",
                },
                {
                    title: "Energy Performance (EnPI) Report",
                    description:
                        "Track the performance of your SEUs and other critical metrics by comparing Actual consumption with Expected and Baseline energy consumption levels.",
                    imgsrc: "/img/analysis-thumbnails/energy-analysis.png",
                    type: "energy",
                    disabled: false,
                    link: "/energy-performance-report",
                },
                {
                    title: "Indoor Conditions",
                    description:
                        "Monitor and improve the indoor conditions of your building to ensure the health and well-being of your occupants.",
                    imgsrc: "/img/analysis-thumbnails/indoor-conditions.png",
                    type: "wellness",
                    disabled: false,
                    link: "/indoor-conditions",
                },
                {
                    title: "Benchmark Your Building",
                    description:
                        "Predict your potential energy and emissions savings by comparing your building’s consumption with industry benchmarks to estimate your levels of waste energy.",
                    imgsrc: "/img/analysis-thumbnails/building-benchmark.png",
                    type: "energy",
                    disabled: false,
                    link: "/building-benchmark",
                    allowed_ids: [348, 310]
                }
            ],
            selected_filter: null
        };
    }

    cardClickHandler(link) {
        this.props.history.push("/analysis" + link);
    }

    getCards() {
        const filter = this.state.selected_filter;
        let list = this.state.items;

        if (filter) {
            list = list.filter((analysis_item) => {
                return analysis_item.type.includes(filter);
            });
        }
        list = _.sortBy(list, "disabled");

        return list.map((analysis_item, i) => {

            if (analysis_item.allowed_ids) {
                const allowed_ids = analysis_item.allowed_ids;
                const user_id = UserStore.getProfile().user_id;
                const user_email = UserStore.getProfile().mail;
                const allowed_domains = ["@opnbuildings.com", "@remotehvac.com"];
                const isAllowedDomain = allowed_domains.some(domain => user_email.toLowerCase().endsWith(domain));
                if (!UserStore.isSuper() && !allowed_ids.includes(user_id) && !isAllowedDomain) return null;
            }

            return (
                <Col span={24} xxl={12} key={analysis_item.title}>
                    <AnalysisCard
                        title={analysis_item.title}
                        imgsrc={analysis_item.imgsrc}
                        description={analysis_item.description}
                        clickHandler={() => this.cardClickHandler(analysis_item.link)}
                        disabled={analysis_item.disabled}
                    />
                </Col>
            );
        });
    }



    selectFilter(filter) {
        const selected_filter = this.state.selected_filter;
        this.setState({
            selected_filter: selected_filter === filter ? null : filter,
        });
    }

    render() {
        return (
            <div
                id="Dashboards"
                className="br-mainpanel br-profile-page floorplan-background"
                style={{ scrollY: "scroll" }}
            >
                <DocumentTitle title="Analysis" />
                <LogoSpinner loading={this.state.loading} />
                <div className="br-container">
                    <div className="row mg-b-10">
                        <div className="col mg-t-30">
                            <PageTitle
                                buttons={[
                                    {
                                        label: "All",
                                        trueCondition: this.state.selected_filter === null,
                                        clickHandler: () => this.selectFilter(null),
                                    },
                                    {
                                        label: "Energy",
                                        trueCondition: this.state.selected_filter === "energy",
                                        clickHandler: () => this.selectFilter("energy"),
                                    },
                                    {
                                        label: "Wellness",
                                        trueCondition: this.state.selected_filter === "wellness",
                                        clickHandler: () => this.selectFilter("wellness"),
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <Row gutter={[50, 50]}>
                        {this.getCards()}
                    </Row>
                </div>
            </div>
        );
    }
}

export default Analysis;
