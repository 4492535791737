import React, { useState, useEffect, useMemo } from 'react';
import styles from './ConsumptionAnalysisDetail.module.scss';
import moment from 'moment';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from 'antd';

import ConsumptionAnalysisActions from '../../../actions/consumptionAnalysisActions';
import ConsumptionAnalysisStore from '../../../stores/consumptionAnalysisStore';
import UserStore from '../../../stores/userStore';

import { Table, Row, Col, Typography, Modal } from 'antd';

import { DocumentTitle } from '../../../components/DocumentTitle';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { TimeframePicker } from '../../../components/TimeframePicker';
import { Icon } from '../../../components/Icon';
import { ShareButton } from '../../../components/ShareButton';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import GeneralUtils from '../../../utils/GeneralUtils';
import Timeframe from '../../../utils/Timeframe';

const { Title, Text, Link } = Typography;

const CHART_HEIGHT = 200;
const PIE_CHART_HEIGHT = 120;

const ConsumptionAnalysisDetail = () => {
    const { token, meterId } = useParams();
    const history = useHistory();
    const [isLoggedIn] = useState(UserStore.loggedIn());
    const { xl } = Grid.useBreakpoint();

    const [consumptionData, setConsumptionData] = useState(null);
    const [analysisInfo, setAnalysisInfo] = useState(null);

    const [timeframeSelection, setTimeframeSelection] = useState(ConsumptionAnalysisStore.getTimeframe());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openingHours, setOpeningHours] = useState(null);
    const [weeklyProfile, setWeeklyProfile] = useState(null)
    const [openingHoursModalVisible, setOpeningHoursModalVisible] = useState(false);

    useEffect(() => {

        const fetchConsumptionData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await ConsumptionAnalysisActions.getConsumptionData(meterId, timeframeSelection.timeframe);
                setConsumptionData(data.consumption_analysis);
                setOpeningHours(data.building.opening_hours)
                setWeeklyProfile(data.week_profile_data)
                setAnalysisInfo({
                    meter: data.meter,
                    building: data.building,
                    type: data.meter.type,
                    search_interval: data.search_interval
                });
            } catch (error) {
                setConsumptionData(null);
                setError(error);
                console.error('Failed to fetch data:', error.code, error.type);
            } finally {
                setLoading(false);
            }
        };

        if (timeframeSelection.timeframe && meterId && isLoggedIn && !token) fetchConsumptionData();

    }, [timeframeSelection.timeframe, meterId, isLoggedIn, token]);

    useEffect(() => {
        const fetchDataViaToken = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await ConsumptionAnalysisActions.getAnalysisByToken(token);
                setConsumptionData(data.consumption_analysis);
                setOpeningHours(data.building.opening_hours)
                setWeeklyProfile(data.week_profile_data)

                const start = moment(data.search_interval.start);
                const end = moment(data.search_interval.end);
                const diffDays = end.diff(start, 'days');

                let granularity = 'year';
                if (diffDays <= 7) {
                    granularity = 'week';
                } else if (diffDays <= 31) {
                    granularity = 'month';
                }

                setTimeframeSelection({
                    timeframe: new Timeframe(start, end),
                    granularity: granularity
                });

                setAnalysisInfo({
                    meter: data.meter,
                    building: data.building,
                    search_interval: data.search_interval
                });

            } catch (error) {
                setError(error);
                console.error('Failed to fetch configuration via token:', error.code, error.type);
                setConsumptionData({});
            } finally {
                setLoading(false);
            }
        };

        if (token) fetchDataViaToken();
    }, [token]);

    const getColors = (value = null) => {
        const good = '#168000';
        const moderate = '#ff9f00';
        const poor = '#d04b4b';
        const worst = '#b84343';

        if (!value) {
            return {
                good,
                moderate,
                poor,
                worst
            }
        } else {
            if (value <= 10) return good;
            if (value <= 20) return moderate;
            return poor;
        }
    }

    const Section = ({ title, children, onClick, onClickText, ...props }) => {
        return <div className={styles.section} {...props} style={{
            backgroundColor: 'white',
            padding: "15px",
            borderRadius: "12px",
        }} styles={{
            body: {
                padding: 15
            }
        }}>
            <div className={styles.cardTitleWrapper}>
                <div className={styles.cardTitle}>{title || ''}</div>
                {onClick && <div className={styles.cardOnClick} onClick={onClick}>{onClickText || ''}</div>}
            </div>
            <div>
                {children}
            </div>
        </div>
    }

    const getPieOptions = (insideConsumption, outsideConsumption) => {
        return {
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                height: PIE_CHART_HEIGHT,
                spacing: [0, 0, 0, 0],
                marginTop: 0,
                marginRight: 0,
                marginBottom: 10,
                marginLeft: 0,
            },
            title: {
                text: null
            },
            tooltip: {
                enabled: true,
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br>Value: <b>{point.y:.1f}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'default',
                    showInLegend: false,
                    innerSize: '75%',
                    size: '100%',
                    colors: ['#55aef8', '#5253be'],
                    dataLabels: {
                        enabled: true,
                        distance: 0,
                        format: '{point.name}<br>{point.percentage:.0f}%',
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            textAlign: 'center'
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Consumption',
                data: [
                    {
                        name: 'Inside',
                        y: insideConsumption,
                        tooltip: `Inside Hours: <b>${insideConsumption && insideConsumption.toFixed(1)}</b>`
                    },
                    {
                        name: 'Outside',
                        y: outsideConsumption,
                        tooltip: `Outside Hours: <b>${outsideConsumption && outsideConsumption.toFixed(1)}</b>`
                    }
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            }
        }
    }

    // Timeframe picker
    const getTimeframePicker = () => {
        const formatted = timeframeSelection.timeframe.format();

        return <Section>
            <div className={styles.timeframePickerSection}>
                <TimeframePicker
                    start={formatted.start}
                    end={formatted.end}
                    onChange={setTimestamps}
                    granularities={['week', 'month', 'year']}
                    defaultGranularity={timeframeSelection.granularity}
                    spacing="10px"
                    backgroundColor='#fff'
                    buttonColor='#5e656e'
                    disableToday
                    requireOneFullDay
                />
            </div>
        </Section>;
    };

    const setTimestamps = (start, end, granularity) => {
        setTimeframeSelection({
            timeframe: new Timeframe(start, end),
            granularity: granularity
        });
    }

    // OOHP
    const getOOHPerformanceBar = () => {
        let value = _.get(consumptionData, 'outside_opening_hours_performance.overall');
        const colors = getColors();
        if (value) value = value.toFixed(0);
        const position = calculateIndicatorPosition(value);

        return (
            <Section title={'Out-of-Hours Performance (OOHP)'} onClickText={'View Opening Hours'} onClick={openingHours ? showOpeningHoursModal : null}>
                <div className={styles.oohpSectionBar}>
                    <div style={{ background: !!value && `linear-gradient(to right, ${colors.good} 0%, ${colors.moderate} 20%, ${colors.poor} 30%, ${colors.worst} 100%)` }} className={styles.bar}>
                        {!!value && <div className={styles.thresholdIndicator} style={{ left: '10%' }}>
                        </div>}
                        {!!value && <div className={styles.thresholdIndicator} style={{ left: '20%' }}>
                        </div>}
                        {!!value && <div className={styles.valueIndicator} style={{ left: `${position}%` }}>
                            <div className={styles.topLabel}>
                                <div className={styles.valueNumber}>{value}</div>
                                <Icon name='ArrowDown' color='black' size={50} />
                            </div>
                        </div>}
                        {!!value && <div className={styles.goodLabel} style={{ left: '0%' }}>
                            Good
                        </div>}
                        {!!value && <div className={styles.poorLabel} style={{ left: '95%' }}>
                            Poor
                        </div>}
                    </div>
                    <div className={styles.explanation}>
                        {/* Thresholds are based on performance for similar buildings. */}
                    </div>
                </div>
            </Section>
        );
    };

    const getOOHPerformanceValue = () => {
        let value = _.get(consumptionData, 'outside_opening_hours_performance.overall');
        const colors = getColors();
        let bgColor = 'white'
        let valColor = 'black'
        let grade = '-'

        if (value) {
            value = value.toFixed(2);
            bgColor = GeneralUtils.getColorFromGradient([
                { position: 0, color: colors.good },
                { position: 20, color: colors.moderate },
                { position: 30, color: colors.poor },
                { position: 100, color: colors.worst }
            ], value);
            valColor = GeneralUtils.getContrastColor(bgColor);
            grade = value <= 10 ? 'Good' : value <= 20 ? 'Average' : 'Poor';
        }

        return (
            <Section title={'OOHP Grade'} tooltip="The ratio of consumption per hour outside opening hours to consumption per hour inside opening hours.">
                <div className={styles.oohpSectionValue}>
                    <div className={styles.valueLabel} style={{
                        backgroundColor: bgColor,
                        color: valColor
                    }}>
                        {grade || '-'}
                    </div>
                    <div className={styles.explanation}>
                        The ratio of consumption per hour outside opening hours to consumption per hour inside opening hours.
                    </div>
                </div>
            </Section>
        );
    };

    const calculateIndicatorPosition = (value) => {
        if (value === null || value === undefined) return 0;
        return Math.min(Math.max(value, 0), 98);
    };

    // Energy Consumption Pie
    const getEnergyConsumptionPie = useMemo(() => {
        const insideConsumption = _.get(consumptionData, 'inside_opening_hours.total_consumption');
        const outsideConsumption = _.get(consumptionData, 'outside_opening_hours.total_consumption');

        if (insideConsumption === 0 && outsideConsumption === 0) {
            return <div className={styles.noDataMessage}>No data available</div>;
        }

        const chartOptions = getPieOptions(insideConsumption, outsideConsumption);

        return (
            <Section title={'Energy Consumption'}>
                <div className={styles.energyConsumptionPieSection} style={{ opacity: loading ? 0 : 1 }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
            </Section>
        );
    }, [consumptionData, loading]);

    // Consumption Hours Pie
    const getConsumptionHoursPie = useMemo(() => {
        const insideConsumption = _.get(consumptionData, 'inside_opening_hours.total_hours');
        const outsideConsumption = _.get(consumptionData, 'outside_opening_hours.total_hours');

        if (insideConsumption === 0 && outsideConsumption === 0) {
            return <div className={styles.noDataMessage}>No data available</div>;
        }

        const chartOptions = getPieOptions(insideConsumption, outsideConsumption);

        return (
            <Section title={'Consumption Hours'}>
                <div className={styles.consumptionHoursPieSection} style={{ opacity: loading ? 0 : 1 }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
            </Section>
        );
    }, [consumptionData, loading]);

    // Table
    const getTableContent = () => {
        const columns = [
            { title: 'Time Period', dataIndex: 'timePeriod', key: 'timePeriod' },
            {
                title: 'Hours',
                dataIndex: 'hours',
                key: 'hours',
                render: (text) => text !== null && text !== undefined ? GeneralUtils.getFormattedNumberWithUnit(text, "", 0) : '-'
            },
            {
                title: `Energy (${analysisInfo?.type === 'WATER' ? 'litres' : 'kWh'})`,
                dataIndex: 'consumption',
                key: 'consumption',
                render: (text) => text !== null && text !== undefined ? GeneralUtils.getFormattedNumberWithUnit(text, "", 0) : '-'
            },
            {
                title: 'Emissions (kgCO2e)',
                dataIndex: 'emissions',
                key: 'emissions',
                render: (text) => text !== null && text !== undefined ? GeneralUtils.getFormattedNumberWithUnit(text, "", 0) : '-'
            },
        ];

        const dataSource = [
            {
                key: '1',
                timePeriod: 'Inside Hours',
                hours: _.get(consumptionData, 'inside_opening_hours.total_hours'),
                consumption: _.get(consumptionData, 'inside_opening_hours.total_consumption'),
                emissions: _.get(consumptionData, 'inside_opening_hours.total_emissions'),
            },
            {
                key: '2',
                timePeriod: 'Outside Hours',
                hours: _.get(consumptionData, 'outside_opening_hours.total_hours'),
                consumption: _.get(consumptionData, 'outside_opening_hours.total_consumption'),
                emissions: _.get(consumptionData, 'outside_opening_hours.total_emissions'),
            },
            {
                key: '3',
                timePeriod: 'Total',
                hours: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_hours'),
                    _.get(consumptionData, 'outside_opening_hours.total_hours')
                ),
                consumption: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_consumption'),
                    _.get(consumptionData, 'outside_opening_hours.total_consumption')
                ),
                emissions: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_emissions'),
                    _.get(consumptionData, 'outside_opening_hours.total_emissions')
                ),
            },
        ];

        return (
            <Section>
                <div className={styles.tableSection}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        bordered={false}
                        size="small"
                        scroll={{ x: true }}
                        className={styles.customTable}
                        rowClassName={styles.customRow}
                    />
                </div>
            </Section>
        );
    };

    const addNonNull = (...args) => {
        if (args.some(arg => arg === null || arg === undefined)) {
            return null;
        }
        return args.reduce((sum, arg) => sum + arg, 0);
    };

    // Grouped Bar Chart
    const getGroupedBarChart = useMemo(() => {
        const colors = getColors();

        const dailyPerformance = _.get(consumptionData, 'outside_opening_hours_performance.weekly_performance', []);

        const chartData = dailyPerformance.map(day => {
            let color;
            let grade;
            if (day.value <= 10) {
                color = colors.good;
                grade = 'Good';
            } else if (day.value <= 20) {
                color = colors.moderate;
                grade = 'Average';
            } else {
                color = colors.poor;
                grade = 'Poor';
            }
            return {
                x: moment(day.date).valueOf(),
                y: day.value,
                color: color,
                grade: grade
            };
        });

        const chartOptions = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: CHART_HEIGHT,
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%b %d}',
                    rotation: -45,
                    align: 'right'
                }
            },
            yAxis: {
                title: {
                    text: 'OOHP KPI (%)'
                },
            },
            tooltip: {
                headerFormat: '<b>Week starting {point.x:%A, %b %d, %Y}</b><br/>',
                pointFormat: 'Grade: {point.grade}<br/>OOHP: {point.y:.0f}'
            },
            series: [{
                name: 'OOHP',
                data: chartData,
                colorByPoint: true
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.1,
                    borderWidth: 0,
                    groupPadding: 0.05,
                    shadow: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            }
        };

        return (
            <Section className={styles.chartSection} title={'Out-of-Hours Performance (OOHP) per Week'}>
                <div className={styles.chartContainer} style={{ opacity: loading ? 0 : 1 }}>
                    <HighchartsReact
                        containerProps={{
                            style: { height: '100%', width: '100%' }
                        }}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
            </Section>
        );
    }, [consumptionData, loading]);

    const getWeeklyProfileChart = useMemo(() => {

        const generateTimeSlots = () => {
            const slots = [];
            const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

            const startDate = moment().startOf('isoWeek'); // Monday start

            days.forEach((day, dayIndex) => {
                const dayHours = weeklyProfile?.[day] || [];

                dayHours.forEach(el => {
                    const date = startDate.clone().add(dayIndex, 'days').add(el.time_of_day, 'hours')
                    const value = el.value;

                    slots.push([
                        date.valueOf(),
                        value
                    ])

                })
            });
            return slots;
        };

        // Plot bands for business hours
        const generatePlotBands = () => {
            if (!openingHours) return [];

            const plotBands = [];
            const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

            days.forEach((day, dayIndex) => {
                const dayHours = openingHours[day] || [];

                dayHours.forEach(({ start, end }) => {
                    const startTime = moment().startOf('isoWeek')
                        .add(dayIndex, 'days')
                        .add(parseInt(start.split(':')[0]), 'hours')
                        .add(parseInt(start.split(':')[1]), 'minutes');

                    const endTime = moment().startOf('isoWeek')
                        .add(dayIndex, 'days')
                        .add(parseInt(end.split(':')[0]), 'hours')
                        .add(parseInt(end.split(':')[1]), 'minutes');

                    plotBands.push({
                        from: startTime.valueOf(),
                        to: endTime.valueOf(),
                        color: '#55aef840',
                        borderColor: '#55aef840',
                        borderWidth: 1
                    });
                });
            });

            return plotBands;
        };

        const chartOptions = {
            chart: {
                type: 'spline',
                backgroundColor: 'transparent',
                height: CHART_HEIGHT
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%a}',
                    rotation: -45,
                    align: 'right'
                },
                tickInterval: 24 * 3600 * 1000, // One day intervals
                gridLineWidth: 1,
                plotBands: generatePlotBands(),
                startOnTick: false,
                endOnTick: false,
                max: moment().startOf('isoWeek').add(6, 'days').endOf('day').valueOf(),

            },
            yAxis: {
                title: {
                    text: `${analysisInfo?.type === 'WATER' ? 'Consumption (litres)' : 'Energy (kWh)'}`
                },
                min: 0
            },
            tooltip: {
                headerFormat: '<b>{point.x:%A, %H:%M}</b><br/>',
                pointFormat: `Consumption: {point.y:.0f} ${analysisInfo?.type === 'WATER' ? 'litres' : 'kWh'}`
            },
            series: [{
                name: 'Weekly Profile',
                data: generateTimeSlots(),
                color: '#55aef8',
                marker: {
                    enabled: false
                }
            }],
            plotOptions: {
                spline: {
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 3
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            }
        };

        return (
            <Section className={styles.chartSection} title={'Out-of-Hours Weekly Profile Chart'}>
                <div className={styles.chartContainer} style={{ opacity: loading ? 0 : 1 }}>
                    <HighchartsReact
                        containerProps={{
                            style: { height: '100%', width: '100%' }
                        }}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                    <div className={styles.customLegend}>
                        <div className={styles.legendItem}>
                            <div className={styles.legendColor} style={{ backgroundColor: '#55aef8', height: '3px' }}></div>
                            <span>Consumption</span>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={styles.legendColor} style={{ backgroundColor: '#55aef840' }}></div>
                            <span>Opening Hours</span>
                        </div>
                    </div>
                </div>
            </Section>
        );
    }, [loading, openingHours, weeklyProfile, analysisInfo?.type]);

    const getObjectForToken = () => {
        const truncatedTimeframe = timeframeSelection.timeframe.truncateEnd();
        const formattedTimeframe = truncatedTimeframe.extendEndByDay().format();

        return {
            type: "CONSUMPTION_ANALYSIS",
            meter_id: +meterId,
            search_interval: formattedTimeframe
        };
    }

    const showOpeningHoursModal = () => {
        setOpeningHoursModalVisible(true);
    };

    const handleOpeningHoursModalClose = () => {
        setOpeningHoursModalVisible(false);
    };

    const renderOpeningHoursModal = () => {

        if (!openingHours) return null;


        const formatHours = (hours) => {
            if (hours.length === 0) return 'Closed';
            return hours.map(({ start, end }) => `${start.slice(0, 5)} - ${end.slice(0, 5)}`).join(', ');
        };

        return (
            <Modal
                title="Opening Hours"
                open={openingHoursModalVisible}
                onCancel={handleOpeningHoursModalClose}
                footer={null}
                width={300}
            >
                <div className={styles.openingHoursContainer}>
                    {Object.entries(openingHours).map(([day, hours]) => (
                        <div key={day} className={styles.openingHoursRow}>
                            <div className={styles.dayName}>{day.charAt(0) + day.slice(1).toLowerCase()}:</div>
                            <div className={styles.hours}>{formatHours(hours)}</div>
                        </div>
                    ))}
                </div>
            </Modal>
        );
    };

    const start = _.get(analysisInfo, 'search_interval.start');
    const end = _.get(analysisInfo, 'search_interval.end');
    const timeframe = token ? `${moment(start).format('DD MMM YYYY')} - ${moment(end).subtract(1, 'days').format('DD MMM YYYY')}` : null;

    const getTitle = () => {
        const lastLocation = sessionStorage.getItem("consumptionAnalysisLastLoc");
        const defaultLocation = "/analysis/consumption-analysis-overview";
        const backText = analysisInfo ? analysisInfo?.meter?.description : "Back";

        return (
            <div
                className={`${styles.pageTitle} tx-18 tx-archivo-semibold shadow-base`}
                style={{
                    padding: isLoggedIn ? '10px 30px 10px 10px' : '10px 20px',
                    cursor: isLoggedIn ? 'pointer' : 'default'
                }}
                onClick={() => isLoggedIn ? history.push(lastLocation || defaultLocation) : null}
            >
                {isLoggedIn && <span className="icon-wrapper">
                    <Icon name='ArrowLeft' size={40} color='#000' />
                </span>}
                {backText}
            </div>
        );
    };

    if (!consumptionData) return <LogoSpinner loading={loading} />;

    if (error) return (
        <div className={styles.mainWrapper}>
            <Row gutter={[30, 30]} justify={'center'} className={styles.mainRow}>
                <Col xs={24}>
                    <Section>
                        <div className={styles.errorContainer}>
                            <Icon name='Warning' size={50} color='#d04b4b' />
                            <Title level={4}>Error Loading Analysis</Title>
                            <Text type="secondary">
                                {error.message || 'Failed to load consumption analysis data. Please try again later.'}
                            </Text>
                        </div>
                    </Section>
                </Col>
            </Row>
        </div>
    );

    return (
        <div className={styles.mainWrapper}>
            <DocumentTitle title={`${analysisInfo?.meter?.description}`} />
            <LogoSpinner loading={loading} />
            {renderOpeningHoursModal()}
            <Row gutter={[30, 30]} justify={'center'} className={styles.mainRow}>
                <Col xs={24} sm={20}>
                    {getTitle()}
                </Col>
                <Col xs={24} sm={4}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
                        <ShareButton
                            condition={meterId && timeframeSelection.timeframe}
                            object={getObjectForToken()}
                            generateToken={ConsumptionAnalysisActions.generateToken}
                            urlPath={`analysis/consumption-analysis/${meterId}/{newToken}`}
                        />
                    </div>
                </Col>
                <Col xs={24} xl={8} order={xl ? 3 : 1}>
                    {token ? (
                        <Section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'orange' }}>
                            <Title className={styles.timeframe} level={4}>{timeframe}</Title>
                            {isLoggedIn ? (
                                <Text className={styles.reportDisclaimer} type='secondary' style={{ fontSize: '12px' }}>
                                    This is a shared report, to select new meter/timeframes for out-of-hours energy analysis, {' '}
                                    <Link href="/analysis/consumption-analysis" style={{ fontSize: '12px' }}>click here</Link>.
                                </Text>
                            ) : (
                                <Text className={styles.reportDisclaimer} type='secondary' style={{ fontSize: '12px' }}>
                                    This is a shared report, to select new meter/timeframes for out-of-hours energy analysis, please {' '}
                                    <Link href="/analysis/consumption-analysis" style={{ fontSize: '12px' }}>log in</Link> or contact your administrator.
                                </Text>
                            )}
                        </Section>
                    ) : (getTimeframePicker())}
                </Col>
                <Col xs={8} xl={6} order={xl ? 1 : 2}>
                    {getOOHPerformanceValue()}
                </Col>
                <Col xs={16} xl={10} order={xl ? 2 : 3}>
                    {getOOHPerformanceBar()}
                </Col>
                <Col xs={12} xl={6} order={4}>
                    {getEnergyConsumptionPie}
                </Col>
                <Col xs={12} xl={6} order={5}>
                    {getConsumptionHoursPie}
                </Col>
                <Col xs={24} xl={12} order={6}>
                    {getTableContent()}
                </Col>
                <Col span={24} order={7}>
                    {getWeeklyProfileChart}
                </Col>
                <Col span={24} order={8} style={{ display: timeframeSelection.granularity === 'week' ? 'none' : 'block' }}>
                    {getGroupedBarChart}
                </Col>
            </Row>
        </div>
    );
};

export default ConsumptionAnalysisDetail;
