import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching and managing building assets. This class interfaces with the API
 * to retrieve, create, update, and delete asset data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const assetsActions = new AssetsActions();
 * assetsActions.getBuildingAssets('building123');
 */
class AssetsActions {
  /**
   * Fetches the assets for a specific building from the API and dispatches an action to store this data.
   *
   * @param {string} buildingId - The unique identifier of the building.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getBuildingAssets(buildingId) {
    return GeneralUtils.get({
      url: `asset/building/${buildingId}`,
      actionType: ActionTypes.ASSETS_FETCHED,
      failToast: "Unable to get building assets, please try again",
    });
  }

  /**
   * Fetches the available asset types from the API and dispatches an action to store this data.
   *
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getAssetTypes() {
    return GeneralUtils.get({
      url: "asset/type",
      actionType: ActionTypes.ASSET_TYPES_FETCHED,
      failToast: "Unable to get asset types, please try again",
    });
  }

  /**
   * Saves a new asset to the API and dispatches an action to store the new asset data.
   *
   * @param {Object} asset - The asset object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveAsset(asset) {
    return GeneralUtils.post({
      url: "asset",
      object: asset,
      actionType: ActionTypes.ASSET_SAVED,
      successToast: `Asset ${asset.name} saved successfully`,
      failToast: `Unable to save asset ${asset.name}, please try again`,
    });
  }

  /**
   * Updates an existing asset in the API and dispatches an action to store the updated data.
   *
   * @param {Object} asset - The asset object to be updated.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateAsset(asset) {
    return GeneralUtils.put({
      url: "asset",
      object: asset,
      actionType: ActionTypes.ASSET_UPDATED,
      successToast: `Asset ${asset.name} updated successfully`,
      failToast: `Unable to update asset ${asset.name}, please try again`,
    });
  }

  /**
   * Deletes a specific asset by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {string} assetId - The unique identifier of the asset to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteAsset(assetId) {
    return GeneralUtils.delete({
      url: `asset/${assetId}`,
      actionType: ActionTypes.ASSET_DELETED,
      successToast: `Asset ${assetId} deleted successfully`,
      failToast: "Unable to delete asset, please try again",
    });
  }
}

const assetsActions = new AssetsActions();
export default assetsActions;
