import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

class NetZeroPlanningActions {

  // Plan Calls
  getPlans() {
    return GeneralUtils.get({
      url: `net-zero-plans`,
      actionType: ActionTypes.NET_ZERO_PLANS_FETCH,
      failToast: "Unable to fetch plans, please try again",
    });
  }

  getPlan(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}`,
      actionType: ActionTypes.NET_ZERO_PLAN_FETCH,
      failToast: "Unable to fetch plan, please try again",
    });
  }

  postPlan(plan) {
    return GeneralUtils.post({
      url: `net-zero-plans`,
      object: plan,
      actionType: ActionTypes.NET_ZERO_PLANS_CREATE,
      failToast: "Unable to create plan, please try again",
    });
  }

  deletePlan(plan) {
    return GeneralUtils.delete({
      url: `net-zero-plans/${plan.id}`,
      actionType: ActionTypes.NET_ZERO_PLANS_DELETE,
      failToast: "Unable to delete plan, please try again",
    });
  }

  updatePlan(plan) {
    return GeneralUtils.put({
      url: `net-zero-plans/${plan.id}`,
      object: plan,
      actionType: ActionTypes.NET_ZERO_PLANS_UPDATE,
      failToast: "Unable to update plan, please try again",
    });
  }

  // Energy Category Calls

  getEnergyCategories(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/energy-categories`,
      actionType: ActionTypes.NET_ZERO_PLANS_ENERGY_CATEGORIES_FETCH,
      failToast: "Unable to fetch energy categories, please try again",
    });
  }

  // Consumption Calls
  getConsumption(planId, sourceCategory) {
    const queryParams = sourceCategory ? `?source_category=${sourceCategory}` : '';
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/usage${queryParams}`,
      actionType: ActionTypes.NET_ZERO_PLANS_CONSUMPTION_FETCH,
      failToast: "Unable to fetch energy consumption, please try again",
    });
  }

  postManualConsumption(planId, consumption) {
    return GeneralUtils.post({
      url: `net-zero-plans/${planId}/usage/sources/manual`,
      object: consumption,
      actionType: ActionTypes.NET_ZERO_PLANS_CONSUMPTION_POST,
      failToast: "Unable to post energy consumption, please try again",
    });
  }

  updateManualConsumption(planId, consumption) {
    return GeneralUtils.put({
      url: `net-zero-plans/${planId}/usage/sources/manual`,
      object: consumption,
      actionType: ActionTypes.NET_ZERO_PLANS_CONSUMPTION_UPDATE,
      failToast: e => e.message
    });
  }

  // Action Calls
  getActions(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/actions`,
      actionType: ActionTypes.NET_ZERO_PLANS_ACTIONS_FETCH,
      failToast: "Unable to fetch actions, please try again",
    });
  }

  getPossibleActions(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/actions/action-categories`,
      actionType: ActionTypes.NET_ZERO_PLANS_POSSIBLE_ACTIONS_FETCH,
      failToast: "Unable to fetch possible actions, please try again",
    });
  }

  postAction(action, planId) {
    return GeneralUtils.post({
      url: `net-zero-plans/${planId}/actions`,
      object: action,
      actionType: ActionTypes.NET_ZERO_PLANS_ACTIONS_POST,
      failToast: "Unable to post action, please try again",
    });
  }

  postActionsImpact(impact, planId) {
    return GeneralUtils.post({
      url: `net-zero-plans/${planId}/actions/impact`,
      object: impact,
      actionType: ActionTypes.NET_ZERO_PLANS_ACTIONS_IMPACT_POST,
      failToast: "Unable to post action impact, please try again",
    });
  }

  updateAction(action, planId) {
    return GeneralUtils.put({
      url: `net-zero-plans/${planId}/actions/${action.id}`,
      object: action,
      actionType: ActionTypes.NET_ZERO_PLANS_ACTIONS_UPDATE,
      failToast: "Unable to update action, please try again",
    });
  }

  deleteAction(action, planId) {
    return GeneralUtils.delete({
      url: `net-zero-plans/${planId}/actions/${action.id}`,
      actionType: ActionTypes.NET_ZERO_PLANS_ACTIONS_DELETE,
      failToast: "Unable to delete action, please try again",
    });
  }

  // Waterfall Chart Data
  getWaterfallChartData(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/waterfall`,
      actionType: ActionTypes.NET_ZERO_PLANS_WATERFALL_CHART_FETCH,
      failToast: (e) => {
        if (e.code !== 404) {
          return "Unable to fetch waterfall chart data, please try again"
        }
      }
    });
  }

  // Glide Path Chart Data
  getGlidePathChartData(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/glide-path`,
      actionType: ActionTypes.NET_ZERO_PLANS_GLIDE_PATH_CHART_FETCH,
      failToast: (e) => {
        if (e.code !== 404) {
          return "Unable to fetch glide path chart data, please try again"
        }
      }
    });
  }

  getEmissionsForecast(planId) {
    return GeneralUtils.get({
      url: `net-zero-plans/${planId}/emissions-forecast`,
      actionType: ActionTypes.NET_ZERO_PLANS_EMISSIONS_FORECAST_FETCH,
      failToast: (e) => {
        if (e.code !== 404) {
          return "Unable to fetch emissions forecast, please try again"
        }
      }
    });
  }
}


const netZeroPlanningActions = new NetZeroPlanningActions();
export default netZeroPlanningActions;
