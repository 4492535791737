import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import UserStore from "../../../stores/userStore";

const useParserTimeout = ({
  timeout,
  onInit,
  onTimePassed,
  initDependency,
}) => {
  useEffect(() => {
    let onCloseTimeout;
    if (initDependency) {
      onInit();
      clearTimeout(onCloseTimeout);
    } else {
      onCloseTimeout = setTimeout(() => {
        onTimePassed();
      }, timeout);
    }
    return () => clearTimeout(onCloseTimeout);
    // eslint-disable-next-line
  }, [initDependency]);
};

const IFrameWrapper = ({ url = "http://localhost:3000/example" }) => {
  const history = useHistory();
  const [initiated, toggleInitiated] = useState(false);

  /** Close iframe if it doesn't send the init event */
  useParserTimeout({
    timeout: 5000,
    onInit: () => console.log("Initiated"),
    onTimePassed: () => console.log("Iframe didn't init"),
    initDependency: initiated,
  });

  const postMessage = (payload) => {
    iframeRef.current?.contentWindow.postMessage(payload, url);
  };

  /** That what we send from iframe */
  const requestHandlersMap = {
    token: async () => {
      postMessage({ token: UserStore.getToken() });
    },
    close: () => {
      toggleInitiated(false);
    },
  };

  const iframeRef = useRef(null);

  useEffect(() => {
    const handleParserEvents = (e) => {
      if (e.data?.name === "init") {
        toggleInitiated(true);
      } else if (e.data?.name === "redirect") {
        history.push(e.data.payload);
      }
      requestHandlersMap.token();
    };

    window.onmessage = handleParserEvents;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="br-mainpanel br-profile-page floorplan-background">
      <iframe
        className="border-0"
        initiated={initiated}
        title="New frontend"
        width="100%"
        height="100%"
        ref={iframeRef}
        src={url}
      />
      {!initiated && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export { IFrameWrapper };
