import Highcharts from "highcharts";
import Constants from "../constants";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import HC_more from "highcharts/highcharts-more";
import HighchartsBoost from "highcharts/modules/boost";

class ChartUtils {
  getColorForProperty(property_name) {
    let color = Constants.ORANGE;
    if (
      property_name.indexOf("Active") > -1 ||
      property_name.indexOf("Enable") > -1 ||
      property_name.indexOf("Outside") > -1 ||
      property_name === "Hot Water System"
    ) {
      color = Constants.GREEN;
    }
    if (
      property_name.indexOf("Fault") > -1 ||
      property_name.indexOf("Alarm") > -1
    ) {
      color = Constants.RED;
    }
    if (
      property_name.indexOf("CO2") > -1 ||
      property_name.indexOf("Intake") > -1
    ) {
      color = Constants.YELLOW;
    }
    if (property_name.indexOf("Heat") > -1) {
      color = Constants.RED;
    }
    if (
      property_name.indexOf("Cool") > -1 ||
      property_name.indexOf("Gas") > -1
    ) {
      color = Constants.BLUE;
    }
    if (
      property_name.indexOf("Damper") > -1 ||
      property_name.indexOf("Electric") > -1
    ) {
      color = Constants.ORANGE;
    }
    return color;
  }

  getLineConsumptionSeries(
    name,
    color,
    dashStyle,
    width,
    showInLegend,
    includeInDataExport
  ) {
    if (color === null) {
      return {
        includeInDataExport: includeInDataExport,
        name: name,
        type: "spline",
        showInLegend: showInLegend,
        lineWidth: width,
        dashStyle: dashStyle,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 1,
        },
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };
    } else {
      return {
        includeInDataExport: includeInDataExport,
        name: name,
        color: color,
        type: "spline",
        showInLegend: showInLegend,
        lineWidth: width,
        dashStyle: dashStyle,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 1,
        },
        data: [],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      };
    }
  }

  getColumnConsumptionSeries(
    name,
    color,
    showInLegend,
    includeInDataExport,
    yAxis = 0
  ) {
    return {
      includeInDataExport: includeInDataExport,
      name: name,
      type: "column",
      color: color,
      data: [],
      yAxis: yAxis,
      showInLegend: showInLegend,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
  }

  getScatterSeries(name, color, showInLegend, includeInDataExport, markerSize) {
    return {
      type: "scatter",
      name: name,
      color: color,
      data: [],
      showInLegend: showInLegend,
      includeInDataExport: includeInDataExport,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
      marker: {
        radius: markerSize,
      },
    };
  }

  getAreaConsumptionSeries(name, color, showInLegend, includeInDataExport) {
    return {
      includeInDataExport: includeInDataExport,
      name: name,
      type: "area",
      color: color,
      data: [],
      step: "left",
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 1,
      },
      showInLegend: showInLegend,
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    };
  }

  resizeCharts(time = 300) {
    setTimeout(function () {
      Highcharts.charts.forEach(function (chart) {
        if (chart && chart.renderTo && chart.renderTo.clientHeight) {
          let newWidth = chart.renderTo.clientWidth;
          let newHeight = chart.renderTo.clientHeight;
          chart.update({
            chart: {
              width: newWidth,
              height: newHeight,
            },
          });
        }
      });
    }, time);
  }

  checkHasData(series) {
    let hasData = false;
    if (series) {
      for (let i = 0; i < series.length; i++) {
        if (series[i].data && series[i].data.length > 0) {
          hasData = true;
          break;
        }
      }
    }
    return hasData;
  }

  initializeDataExplorerHighchartsConfig() {
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    HC_more(Highcharts);
    HighchartsBoost(Highcharts);

    // Add Highcharts wrap to modify the 'post' method for exporting
    Highcharts.wrap(Highcharts, 'post', function (proceed, url, data, formAttributes) {
      var frame;
      if (!Highcharts.exportFrame) {
        frame = document.createElement('iframe');
        frame.id = 'hc-export-frame';
        frame.style.display = 'none';
        document.body.appendChild(frame);
        Highcharts.exportFrame = frame;
      }
      formAttributes = Highcharts.merge({ target: 'hc-export-frame' }, formAttributes);
      proceed.call(this, url, data, formAttributes);
    });

    // Highcharts bandaid bugfix from https://github.com/highcharts/highcharts/issues/3967
    Highcharts.Pointer.prototype.getHoverData = function (
      existingHoverPoint,
      existingHoverSeries,
      series,
      isDirectTouch,
      shared,
      e
    ) {
      var hoverPoint,
        hoverPoints = [],
        hoverSeries = existingHoverSeries,
        useExisting = !!(isDirectTouch && existingHoverPoint),
        notSticky = hoverSeries && !hoverSeries.stickyTracking,
        filter = function (s) {
          return (
            s.visible &&
            !(!shared && s.directTouch) && // #3821
            Highcharts.pick(s.options.enableMouseTracking, true)
          );
        };

      // Which series to look in for the hover point
      var searchSeries = notSticky ?
        // Only search on hovered series if it has stickyTracking false
        [hoverSeries] :
        // Filter what series to look in.
        series.filter(function (s) {
          return filter(s) && s.stickyTracking;
        });

      // Use existing hovered point or find the one closest to coordinates.
      hoverPoint = useExisting || !e ?
        existingHoverPoint :
        this.findNearestKDPoint(searchSeries, shared, e);

      // Assign hover series
      hoverSeries = hoverPoint && hoverPoint.series;

      // If we have a hoverPoint, assign hoverPoints.
      if (hoverPoint) {
        // When tooltip is shared, it displays more than one point
        if (shared && !hoverSeries.noSharedTooltip) {
          searchSeries = series.filter(function (s) {
            return filter(s) && !s.noSharedTooltip;
          });

          // Get all points with the same x value as the hoverPoint
          searchSeries.forEach(function (s) {
            var point = Highcharts.find(s.points, function (p) {
              return p.clientX === hoverPoint.clientX && !p.isNull;
            });

            if (Highcharts.isObject(point)) {
              /*
                * Boost returns a minimal point. Convert it to a usable
                * point for tooltip and states.
                */
              if (s.chart.isBoosting) {
                point = s.getPoint(point);
              }
              hoverPoints.push(point);
            }
          });
        } else {
          hoverPoints.push(hoverPoint);
        }
      }
      return {
        hoverPoint: hoverPoint,
        hoverSeries: hoverSeries,
        hoverPoints: hoverPoints
      };
    };

    Highcharts.setOptions({
      lang: {
        swapAxes: 'Swap Y Axes',
        togglePercentage: 'Toggle Percentage / Values',
        refreshData: 'Refresh Data',
        chartContextualMenu: 'Chart Menu'
      }
    });
  }
}

const chartUtils = new ChartUtils();
export default chartUtils;
