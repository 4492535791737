import React, { useEffect, useState, useCallback } from 'react';
import styles from './NetZeroPlanning.module.scss';
import { useParams } from 'react-router-dom';

import NetZeroPlanningActions from '../../../actions/netZeroPlanningActions';
import UserStore from '../../../stores/userStore';

import { DocumentTitle } from '../../../components/DocumentTitle';
import { EditableNameInput } from '../../../components/EditableNameInput';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { Icon } from '../../../components/Icon';
import ChartUtils from '../../../utils/ChartUtils.js';
import { Row, Col, Button, Card, Tabs, Modal, Select } from 'antd';

import PlanModal from './components/PlanModal.js';
import WaterfallChart from './components/WaterfallChart.js';
import GlidePathChart from './components/GlidePathChart.js';
import ActionsTable from './components/ActionsTable.js';
import ConsumptionTable from './components/ConsumptionTable.js';
import ActionModal from './components/ActionModal.js';
import ConsumptionModal from './components/ConsumptionModal.js';
import EmissionsBreakdown from './components/EmissionsBreakdown.js';
import Constants from '../../../constants';

const NetZeroPlanning = () => {
    // External State
    const { token } = useParams();
    const [isSuper] = useState(UserStore.isSuper());
    const [isAdmin] = useState(UserStore.isAdmin());
    const [isLoggedIn] = useState(UserStore.loggedIn());

    // UI State
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('waterfall');
    const [menuOpen, setMenuOpen] = useState(true);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [error, setError] = useState(null);

    // PLANS
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [newPlanName, setNewPlanName] = useState('');
    const [editingPlan, setEditingPlan] = useState(null);

    // ACTIONS
    const [actions, setActions] = useState([]);
    const [editingAction, setEditingAction] = useState(null);
    const [showActionModal, setShowActionModal] = useState(false);

    // Consumption State and Type
    const [consumptionTypes, setConsumptionTypes] = useState([]);
    const [consumptionData, setConsumptionData] = useState([]);

    // State for waterfall chart data
    const [waterfallData, setWaterfallData] = useState(null);
    const [glidePathData, setGlidePathData] = useState(null);
    const [emissionsForecastData, setEmissionsForecastData] = useState(null);
    // State for selected target year
    const [selectedTargetYear, setSelectedTargetYear] = useState(null);
    const [selectedScope, setSelectedScope] = useState('combined');

    // Add new state for possible actions
    const [possibleActions, setPossibleActions] = useState([]);

    // Add new state for consumption modal
    const [showAddConsumptionModal, setShowAddConsumptionModal] = useState(false);

    const handleUpdateConsumption = async (data) => {
        setLoading(true);
        try {
            await NetZeroPlanningActions.updateManualConsumption(selectedPlan?.id, data);
            const updatedConsumption = await NetZeroPlanningActions.getConsumption(selectedPlan?.id, Constants.SOURCE_CATEGORIES.MANUAL);
            setConsumptionData(updatedConsumption);
            fetchWaterfallData(selectedPlan?.id);
            fetchGlidePathChartData(selectedPlan?.id);
            fetchEmissionsForecast(selectedPlan?.id);
        } catch (error) {
            console.error('Failed to update consumption data:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    const fetchPlans = async () => {
        setLoading(true);
        try {
            const response = await NetZeroPlanningActions.getPlans();
            setPlans(response);
        } catch (error) {
            console.error('Failed to fetch configurations:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (plans.length === 0) fetchPlans();
    }, [plans.length]);

    const cleanUp = () => {
        setSelectedTargetYear(null);
        setNewPlanName(null)
        setSelectedPlan(null);
        setConsumptionData([]);
        setConsumptionTypes([]);
        setActions([]);
        setError(null);
        setGlidePathData(null);
        setWaterfallData(null);
        setPossibleActions([]);
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        ChartUtils.resizeCharts(400);
    }

    const getContextualMenu = () => {
        if (token) return null;
        const adminOrSuper = isAdmin || isSuper;

        return (
            <div className={`${styles.contextualMenu} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.label}>Net Zero Planning</div>
                {adminOrSuper && <div className={styles.planButtons}>
                    <Button
                        className="button light-blue"
                        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
                        size="small"
                        onClick={() => setShowPlanModal(true)}
                    >New Plan</Button>
                </div>}
                <div className={styles.plansList}>
                    {plans.map((plan) => (
                        getPlanCard(plan)
                    ))}
                    {plans.length === 0 && <div className={styles.noReports}>No plans available</div>}
                </div>
                {!token && <Button
                    className={`${styles.menuButton} ${menuOpen ? styles.open : styles.close}`}
                    onClick={toggleMenu}
                    icon={<Icon name={menuOpen ? 'ArrowLeft' : 'ArrowRight'} size={30} />}
                    size="small"
                    style={{ border: 'none', outline: 'none' }}
                />}
            </div >
        );
    }

    const getPlanCard = (plan) => {
        const { id, base_year, target_year, description } = plan;
        const isSelected = selectedPlan?.id === id;
        const adminOrSuper = isAdmin || isSuper;

        return <div key={id} className={`${styles.reportItem} ${isSelected ? styles.highlighted : ''}`} onClick={() => handleSelectPlan(plan)}>
            <div>
                <div className={styles.reportName}>{description}</div>
                <div className={styles.reportDescription}>{base_year} - {target_year}</div>
            </div>
            {adminOrSuper && <div className={styles.reportDelete}>
                <Button
                    icon={<Icon name="Edit" color={'#fff'} size={20} />}
                    style={{ background: '#d77a29', marginRight: '5px' }}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditPlan(plan);
                    }}
                />
                <Button
                    icon={<Icon name="Delete" color={'#fff'} size={20} />}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePlan(plan);
                    }}
                />
            </div>}
        </div>
    }

    const handleEditAction = async (action) => {
        await fetchPossibleActions(selectedPlan.id);
        setEditingAction(action);
        setShowActionModal(true);
    };

    const handleColumnClick = async (actionId) => {
        await fetchPossibleActions(selectedPlan.id);
        const freshActions = await NetZeroPlanningActions.getActions(selectedPlan.id);
        const action = freshActions.find(action => action.id === actionId);
        if (action) {
            setEditingAction(action);
            setShowActionModal(true);
        }
    }

    const handleEditTarget = async () => {
        const plan = await NetZeroPlanningActions.getPlan(selectedPlan.id);
        setEditingPlan(plan);
        setShowPlanModal(true);
    }

    // Consumption Stuff
    const handleEditConsumption = (updatedConsumption) => {
        const newData = consumptionData.map(item =>
            item.category === updatedConsumption.category
                ? updatedConsumption
                : item
        );

        setConsumptionData(newData);
        handleUpdateConsumption(newData);
    };

    const handleDeleteConsumption = (rowToDelete, year) => {
        if (year === 'all') {
            // Remove the energy type completely
            const newData = consumptionData.filter(item =>
                item.category !== rowToDelete.category
            );
            setConsumptionData(newData);
            handleUpdateConsumption(newData);
        } else {
            // Remove just the specific year
            const newData = consumptionData.map(item => {
                if (item.category === rowToDelete.category) {
                    return {
                        ...item,
                        data: item.data.filter(d => d.year !== year)
                    };
                }
                return item;
            });
            setConsumptionData(newData);
            handleUpdateConsumption(newData);
        }
    };

    const handleAddConsumptionData = async () => {
        const updatedConsumption = await NetZeroPlanningActions.getConsumption(selectedPlan?.id, Constants.SOURCE_CATEGORIES.MANUAL);
        setConsumptionData(updatedConsumption);
        setShowAddConsumptionModal(true);
    };

    // Plan handlers
    const handleSelectPlan = async (plan) => {
        cleanUp();
        setSelectedPlan(plan);
        setSelectedTargetYear(plan?.interim_targets[0]?.target_year || plan?.target_year);
        setLoading(true);
        try {
            const energyCategories = await NetZeroPlanningActions.getEnergyCategories(plan.id);
            const consumptionData = await NetZeroPlanningActions.getConsumption(plan.id, Constants.SOURCE_CATEGORIES.MANUAL);
            const actions = await NetZeroPlanningActions.getActions(plan.id);

            setConsumptionTypes(energyCategories);
            setConsumptionData(consumptionData);
            setActions(actions);
            fetchWaterfallData(plan.id);
            fetchGlidePathChartData(plan.id);
            fetchEmissionsForecast(plan.id);

            if (consumptionData.length === 0) {
                setActiveTab('consumption');
            }

        } catch (error) {
            console.error('Failed to fetch energy categories:', error.code, error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleSavePlan = async (newPlan) => {
        setLoading(true);
        try {
            if (editingPlan) {
                const updatedPlan = await NetZeroPlanningActions.updatePlan(newPlan);
                await fetchPlans();
                handleSelectPlan(updatedPlan)
                setShowPlanModal(false);
                fetchWaterfallData(updatedPlan.id);
                fetchGlidePathChartData(updatedPlan.id);
                fetchEmissionsForecast(updatedPlan.id);
            } else {
                const savedPlan = await NetZeroPlanningActions.postPlan(newPlan);
                await NetZeroPlanningActions.postManualConsumption(savedPlan.id, []);
                await fetchPlans();
                handleSelectPlan(savedPlan);
                setShowPlanModal(false);
            }
        } catch (error) {
            console.error('Failed to save the list: ', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdatePlanName = async (newName) => {
        setLoading(true);
        try {
            await NetZeroPlanningActions.updatePlan({
                ...selectedPlan,
                description: newName
            });
            await fetchPlans();
        } catch (error) {
            console.error('Failed to update plan:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePlan = async (plan) => {
        Modal.confirm({
            title: 'Delete Plan',
            content: `Are you sure you want to delete "${plan.description}"? This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    setLoading(true);
                    await NetZeroPlanningActions.deletePlan(plan);
                    if (selectedPlan?.id === plan.id) {
                        cleanUp();
                    }
                    await fetchPlans();
                } catch (error) {
                    console.error('Failed to delete plan:', error.code, error.type);
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    const handleClosePlanModal = () => {
        setShowPlanModal(false);
        setEditingPlan(null);
    };

    const handleEditPlan = (plan) => {
        setEditingPlan(plan);
        setShowPlanModal(true);
    };

    // Actions
    const handleAddActionButton = async () => {
        await fetchPossibleActions(selectedPlan.id);
        setShowActionModal(true);
    }

    const handleSaveAction = async (newAction) => {
        setLoading(true);
        try {
            if (editingAction) {
                await NetZeroPlanningActions.updateAction({ ...newAction, id: editingAction.id }, selectedPlan.id);
            } else {
                await NetZeroPlanningActions.postAction(newAction, selectedPlan.id);
            }
            const newActions = await NetZeroPlanningActions.getActions(selectedPlan.id);
            setActions(newActions);
            fetchWaterfallData(selectedPlan.id);
            fetchGlidePathChartData(selectedPlan.id);
            fetchEmissionsForecast(selectedPlan.id);
            setEditingAction(null);
        } catch (error) {
            console.error('Failed to add/edit action:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseActionModal = () => {
        setShowActionModal(false);
        setEditingAction(null);
    };

    const fetchPossibleActions = useCallback(async (planId) => {
        try {
            const response = await NetZeroPlanningActions.getPossibleActions(planId);
            setPossibleActions(response);
        } catch (error) {
            console.error('Failed to fetch possible actions:', error);
            setPossibleActions([]);
        }
    }, []);

    const handleDeleteAction = (action) => {
        Modal.confirm({
            title: 'Delete Action',
            content: `Are you sure you want to delete "${action.description}"? This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    setLoading(true);
                    await NetZeroPlanningActions.deleteAction(action, selectedPlan.id);
                    const newActions = await NetZeroPlanningActions.getActions(selectedPlan.id);
                    fetchWaterfallData(selectedPlan.id);
                    fetchGlidePathChartData(selectedPlan.id);
                    fetchEmissionsForecast(selectedPlan.id);
                    setActions(newActions);
                } catch (error) {
                    console.error('Failed to delete action:', error.code, error.type);
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    // Charts
    const fetchWaterfallData = async (id) => {
        setError(null);
        if (!id) return;
        try {
            const data = await NetZeroPlanningActions.getWaterfallChartData(id);
            setWaterfallData(data);
        } catch (error) {
            setError(error);
            console.error('Failed to fetch waterfall chart data:', error.code, error.message);
        }
    }

    const fetchGlidePathChartData = async (id) => {
        setError(null);
        if (!id) return;
        try {
            const data = await NetZeroPlanningActions.getGlidePathChartData(id);
            setGlidePathData(data);
        } catch (error) {
            setError(error);
            console.error('Failed to fetch glide path chart data:', error.code, error.message);
        }
    }

    const fetchEmissionsForecast = async (id) => {
        setError(null);
        if (!id) return;
        try {
            const data = await NetZeroPlanningActions.getEmissionsForecast(id);
            setEmissionsForecastData(data);
        } catch (error) {
            setError(error);
            console.error('Failed to fetch emissions forecast:', error.code, error.message);
        }
    }

    const getRightExtraHeaderTab = (activeTab) => {
        switch (activeTab) {
            case 'actions':
                return (
                    <Button
                        className="button green"
                        onClick={handleAddActionButton}
                        style={{height: '34px', margin: '20px 0'}}
                    >
                        <Icon name="AddCircleFilled" />
                        Add Action
                    </Button>
                );
            case 'consumption':
                return (
                    <Button
                        className="button green"
                        onClick={handleAddConsumptionData}
                        style={{height: '34px', margin: '20px 0'}}
                    >
                        <Icon name="AddCircleFilled" />
                        Add Consumption Data
                    </Button>
                );
            case 'waterfall':
            case 'emissions':
            case 'glidepath':
                const options = [
                    ...selectedPlan.interim_targets.map(t => ({ value: t.target_year, label: `${t.target_year} Target` })),
                    { value: selectedPlan.target_year, label: `${selectedPlan.target_year} Target` },
                ]
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '5px'}}>
                        {getScopeSelectionButtons()}
                        <Select
                            popupMatchSelectWidth={false}
                            value={selectedTargetYear}
                            onChange={setSelectedTargetYear}
                            options={options}
                        >
                        </Select>
                    </div>
                );
            default:
                return null;
        }
    };

    const getScopeSelectionButtons = () => {
        const options = [
            { value: 'combined', label: 'Combined' },
            { value: 'scope_one', label: 'Scope 1' },
            { value: 'scope_two', label: 'Scope 2' },
        ];

        return (
            <div style={{ display: 'flex', gap: '10px' }}>
                {options.map(option => (
                    <Button
                        key={option.value}
                        style={{
                            background: selectedScope === option.value ? '#1ca289' : '',
                        }}
                        type={selectedScope === option.value ? 'primary' : 'secondary'}
                        onClick={() => setSelectedScope(option.value)}
                    >
                        {option.label}
                    </Button>
                ))}
            </div>
        );
    }



    return (
        <div className={`${styles.NetZeroPlanning} ${!isLoggedIn && styles.outOfLogin} br-mainpanel br-profile-page floorplan-background`}>
            <DocumentTitle title="Net Zero Planning" />
            <LogoSpinner loading={loading} />
            {getContextualMenu()}
            <PlanModal
                showPlanModal={showPlanModal}
                onPlanSave={handleSavePlan}
                editingPlan={editingPlan}
                onClosePlanModal={handleClosePlanModal}
            />
            <ActionModal
                showActionModal={showActionModal}
                onCloseActionModal={handleCloseActionModal}
                consumptionTypes={consumptionTypes}
                selectedPlan={selectedPlan}
                onActionSave={handleSaveAction}
                onCalculateImpact={NetZeroPlanningActions.postActionsImpact}
                possibleActions={possibleActions}
                editingAction={editingAction}
            />
            <ConsumptionModal
                visible={showAddConsumptionModal}
                onClose={() => setShowAddConsumptionModal(false)}
                consumptionData={consumptionData}
                consumptionTypes={consumptionTypes}
                baseYear={selectedPlan?.base_year}
                targetYear={selectedPlan?.target_year}
                saveConsumptionData={handleUpdateConsumption}
            />

            <div className={`${styles.mainWrapper} ${menuOpen && !token ? '' : styles.menuClosed}`}>
                {selectedPlan && <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                    <Col span={24}>
                        <EditableNameInput
                            name={newPlanName || selectedPlan?.description}
                            onNameChange={setNewPlanName}
                            onNameChangeComplete={handleUpdatePlanName}
                        />
                    </Col>
                    <Col span={24}>
                        <Card styles={{ body: { paddingTop: '0px', position: 'relative', height: '78vh' } }}>
                            <Tabs
                                destroyInactiveTabPane
                                activeKey={activeTab}
                                onChange={(key) => setActiveTab(key)}
                                tabBarStyle={{ borderBottom: 'none' }}
                                tabBarExtraContent={{
                                    right: getRightExtraHeaderTab(activeTab)
                                }}
                                items={[
                                    {
                                        key: 'waterfall',
                                        label: 'Waterfall Chart',
                                        children: (
                                            <div key={`waterfall-${activeTab}`} style={{ height: '68vh' }}>
                                                <WaterfallChart
                                                    data={waterfallData}
                                                    loading={loading}
                                                    error={error}
                                                    columnClickHandler={handleColumnClick}
                                                    editTargetHandler={handleEditTarget}
                                                    selectedTargetYear={selectedTargetYear}
                                                    selectedScope={selectedScope}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        key: 'glidepath',
                                        label: 'Glide Path Chart',
                                        children: (
                                            <div key={`glidepath-${activeTab}`} style={{ height: '68vh' }}>
                                                <GlidePathChart
                                                    actions={actions}
                                                    data={glidePathData}
                                                    loading={loading}
                                                    error={error}
                                                    selectedTargetYear={selectedTargetYear}
                                                    selectedScope={selectedScope}
                                                    interimTargets={selectedPlan?.interim_targets}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        key: 'emissions',
                                        label: 'Emissions Breakdown',
                                        children: (
                                            <div key={`emissions-${activeTab}`} style={{ height: '68vh' }}>
                                                <EmissionsBreakdown
                                                    selectedTargetYear={selectedTargetYear}
                                                    energyCategories={consumptionTypes}
                                                    data={emissionsForecastData}
                                                    loading={loading}
                                                    error={error}
                                                    selectedScope={selectedScope}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        key: 'separator',
                                        label: '|',
                                        children: null,
                                        disabled: true,
                                    },
                                    {
                                        key: 'actions',
                                        label: 'Actions',
                                        children: (
                                            <div key={`actions-${activeTab}`} style={{ height: '68vh' }}>
                                                <ActionsTable
                                                    actions={actions}
                                                    onEditAction={handleEditAction}
                                                    onDeleteAction={handleDeleteAction}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        key: 'consumption',
                                        label: (
                                            <span>
                                                Consumption
                                            </span>
                                        ),
                                        children: (
                                            <div key={`consumption-${activeTab}`} style={{ overflow: 'auto', height: '68vh' }}>
                                                <ConsumptionTable
                                                    consumptionData={consumptionData}
                                                    consumptionTypes={consumptionTypes}
                                                    baseYear={selectedPlan.base_year}
                                                    targetYear={selectedPlan.target_year}
                                                    onEditConsumption={handleEditConsumption}
                                                    onDeleteConsumption={handleDeleteConsumption}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Card>
                    </Col>
                </Row>}
            </div>
        </div>
    );
};

export default NetZeroPlanning;