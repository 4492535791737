import React, { useState, useEffect, useRef } from 'react';
import styles from './ConsumptionAnalysisOverview.module.scss';
import { useHistory } from 'react-router-dom';

import ConsumptionAnalysisActions from '../../../actions/consumptionAnalysisActions';
import OrganisationActions from '../../../actions/organisationActions';

import UserStore from '../../../stores/userStore';
import ConsumptionAnalysisStore from '../../../stores/consumptionAnalysisStore';
import OrganisationStore from '../../../stores/organisationStore';

import { Row, Col, Typography, Tag, Select, Flex, Grid } from 'antd';

import { LogoSpinner } from '../../../components/LogoSpinner';
import { TimeframePicker } from '../../../components/TimeframePicker';
import { AntTable } from '../../../components/AntTable';

import GeneralUtils from '../../../utils/GeneralUtils';
import Timeframe from '../../../utils/Timeframe';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const ConsumptionAnalysisOverview = (props) => {
    const history = useHistory();
    const [isLoggedIn] = useState(UserStore.loggedIn());
    const [isSuper] = useState(UserStore.isSuper());
    const screens = useBreakpoint();

    const [availableMeters, setAvailableMeters] = useState([]);
    const [timeframeSelection, setTimeframeSelection] = useState(ConsumptionAnalysisStore.getTimeframe());

    const [selectedOrg, setSelectedOrg] = useState(UserStore.getUser().fk_organisation_id)
    const [organisations, setOrganisations] = useState([]);

    const [metersLoading, setMetersLoading] = useState(false);

    const isFreshMount = useRef(true);

    const fetchMeters = async () => {
        setMetersLoading(true);
        try {
            const data = await (isSuper
                ? ConsumptionAnalysisActions.getConsumptionAnalysisMetersList(timeframeSelection.timeframe, selectedOrg)
                : ConsumptionAnalysisActions.getConsumptionAnalysisMetersList(timeframeSelection.timeframe));
            setAvailableMeters(data);
        } catch (error) {
            console.error('Failed to fetch data:', error.code, error.type);
        } finally {
            setMetersLoading(false);
        }
    };

    // Initial load from store or fetch
    useEffect(() => {
        const data = ConsumptionAnalysisStore.getConsumptionAnalysisList();

        if (data.length) {
            setAvailableMeters(data);
        } else if (timeframeSelection.timeframe && isLoggedIn && selectedOrg) {
            fetchMeters();
        }

        setTimeout(() => {
            isFreshMount.current = false;
        }, 0);

        // eslint-disable-next-line
    }, []);

    // Fetch when timeframe or org changes
    useEffect(() => {
        if (!isFreshMount.current && timeframeSelection.timeframe && isLoggedIn && selectedOrg) {
            fetchMeters();
        }
        // eslint-disable-next-line
    }, [timeframeSelection.timeframe, selectedOrg]);

    useEffect(() => {
        if (!organisations.length && isLoggedIn && isSuper) fetchOrgs();
        // eslint-disable-next-line
    }, []);

    const fetchOrgs = async () => {
        try {
            const orgs = OrganisationStore.getOrganisations();
            const data = orgs.length ? orgs : await OrganisationActions.getOrganisations();
            setOrganisations(data);
        } catch (error) {
            console.error('Failed to fetch organisations: ', error.code, error.type)
        }
    }

    const getColors = (value = null) => {
        const good = '#168000';
        const moderate = '#ff9f00';
        const poor = '#d04b4b';
        const worst = '#b84343';

        if (value === null) {
            return {
                good,
                moderate,
                poor,
                worst
            }
        } else {
            if (value <= 10) return good;
            if (value <= 20) return moderate;
            return poor;
        }
    }

    const getMetersTable = () => {
        const tableColumns = [
            {
                title: 'Building',
                dataIndex: ['building', 'name'],
                key: 'buildingName',
                filterable: true,
            },
            {
                title: 'Meter',
                dataIndex: ['meter', 'name'],
                key: 'meterName',
                filterable: true,
                width: '25%'
            },
            {
                title: 'OOHP Score',
                dataIndex: ['consumption_analysis', 'oohp_score'],
                key: 'oohpScore',
                defaultSortOrder: 'descend',
                default: true,
                render: (value) => {
                    if (value === null) return null;
                    const grade = value <= 10 ? 'Good' : value <= 20 ? 'Average' : 'Poor';
                    const backgroundColor = getColors(value);
                    return (
                        <Tag
                            style={{
                                backgroundColor,
                                color: 'white',
                                fontWeight: 500,
                                border: 'none',
                                fontSize: '14px',
                                width: '90px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ fontSize: '14px', margin: '3px 0 1px 0' }}>{grade}</div>
                            <div style={{ fontSize: '13px', opacity: 0.6, margin: '1px 0 3px 0' }}>{value.toFixed(0)}</div>
                        </Tag>
                    );
                },
            },
            {
                title: 'Out-of-Hours Consumption',
                dataIndex: ['consumption_analysis', 'total_out_of_hours_consumption'],
                key: 'totalOutOfHoursConsumption',
                render: (value, record) => {
                    if (value === null) return null;
                    const unit = record?.meter?.type === 'WATER' ? 'Litres' : 'kWh';
                    return `${GeneralUtils.getFormattedNumberWithUnit(value, '', 0)} ${unit}`;
                },
            },
            {
                title: 'Out-of-Hours Emissions',
                dataIndex: ['consumption_analysis', 'total_out_of_hours_emissions'],
                key: 'totalOutOfHoursEmissions',
                render: (value) => value === null ? null : `${GeneralUtils.getFormattedNumberWithUnit(value, '', 0)} kgCO2e`,
            },
        ];


        const tags = [
            {
                label: "All",
                condition: function (record) {
                    return !!record?.meter?.type
                },
                default: true,
            },
            {
                label: "Electric",
                condition: function (record) {
                    return record?.meter?.type === 'ELECTRIC'
                }
            },
            {
                label: "Gas",
                condition: function (record) {
                    return record?.meter?.type === 'GAS'
                },
            },
            {
                label: "Water",
                condition: function (record) {
                    return record?.meter?.type === 'WATER'
                },
            }
        ]

        return (
            <AntTable
                tableId="consumption-analysis-meters"
                columns={tableColumns}
                tags={tags}
                dataSource={availableMeters}
                hasInputFilter={true}
                multiTagFilter={true}
                stickyFilters={false}
                onRow={(record) => ({
                    onClick: () => {
                        sessionStorage.setItem("consumptionAnalysisLastLoc", props.location.pathname);
                        history.push(`/analysis/consumption-analysis/${record.meter.id}`);
                    },
                })}
                scroll={{ x: 1000 }}
                rowKey={(record) => `${record.meter.id}`}
            />
        )
    }

    const getTimeframePicker = () => {
        const formatted = timeframeSelection.timeframe.format();
        return <div className={styles.timeframePickerSection}>
            <TimeframePicker
                start={formatted.start}
                end={formatted.end}
                onChange={setTimestamps}
                granularities={['week', 'month']}
                defaultGranularity={timeframeSelection.granularity}
                spacing="10px"
                backgroundColor='#fff'
                buttonColor='#5e656e'
                disableToday
                requireOneFullDay
            />
        </div>;
    };

    const getOrganisationSelect = () => {
        if (!isSuper) return null;
        return <div className={styles.organisationSelector} >
            <Select
                size='large'
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{
                    width: '300px',
                    borderRadius: '12px !important',
                }}
                dropdownStyle={{
                    borderRadius: '12px',
                }}
                placeholder="Select an organization"
                onChange={(value) => setSelectedOrg(value)}
                value={selectedOrg}
            >
                {organisations
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((org) => (
                        <Select.Option key={org.organisation_id} value={org.organisation_id}>
                            {org.name}
                        </Select.Option>
                    ))}
            </Select>
        </div>
    };

    const setTimestamps = (start, end, granularity) => {

        setTimeframeSelection({
            timeframe: new Timeframe(start, end),
            granularity: granularity
        });

        ConsumptionAnalysisActions.setTimeframe({
            timeframe: new Timeframe(start, end),
            granularity: granularity
        });
    }


    return (<div className={styles.mainWrapper}>
        <LogoSpinner loading={metersLoading} />
        <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
            <Col xs={24} lg={12}>
                <Flex style={{ flexDirection: 'column', alignItems: screens.lg ? 'flex-start' : 'center' }} >
                    <Title className={styles.mainTitle} level={3}>Out-of-Hours Energy Analysis</Title>
                    {getOrganisationSelect()}
                </Flex>
            </Col>
            <Col xs={24} lg={12}>
                <Flex style={{ justifyContent: screens.lg ? 'flex-end' : 'center' }} >
                    {getTimeframePicker()}
                </Flex>
            </Col>
            <Col span={24}>
                <div className={styles.metersTableContainer}>
                    {getMetersTable()}
                </div>
            </Col>
        </Row>
    </div>);
};

export default ConsumptionAnalysisOverview;
