import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Input, Select, DatePicker, Row, Col, Table, Statistic, Spin, Collapse, Button, InputNumber, Typography } from 'antd';
import dayjs from 'dayjs';
import GeneralUtils from '../../../../utils/GeneralUtils';
import { debounce } from 'lodash';
import ComponentUtils from '../../../../utils/ComponentUtils';
import netZeroUtils from './NetZeroUtils';

// Utility functions
const getFormShape = (category) => {
    const baseFields = ['category', 'description', 'start_date'];
    const wasteReductionFields = ['energy_category', 'percentage_reduction'];
    const heatPumpFields = ['replacement_percentage'];
    const solarPvFields = ['kw_capacity'];
    const windTurbineFields = ['kw_capacity'];

    switch (category) {
        case 'GENERIC':
            return [...baseFields];
        case 'ENERGY_EFFICIENCY':
            return [...baseFields, ...wasteReductionFields];
        case 'HEAT_PUMP':
            return [...baseFields, ...heatPumpFields];
        case 'SOLAR_PV':
            return [...baseFields, ...solarPvFields];
        case 'WIND_TURBINES':
            return [...baseFields, ...windTurbineFields];
        default:
            return baseFields;
    }
};

const createNewAction = (values, calculatedImpact = null) => {
    const baseAction = {
        category: values.category,
        description: values.description,
        start_date: values.start_date.format('YYYY-MM-01'),
        cost: values.cost,
        ...(calculatedImpact ? { impacts: calculatedImpact } : {})
    };

    const categorySpecificParameters = {};
    switch (values.category) {
        case 'ENERGY_EFFICIENCY':
            categorySpecificParameters.energy_category = values.energy_category;
            categorySpecificParameters.percentage_reduction = parseInt(values.percentage_reduction);
            break;
        case 'HEAT_PUMP':
            categorySpecificParameters.replacement_percentage = parseInt(values.replacement_percentage);
            break;
        case 'SOLAR_PV':
        case 'WIND_TURBINE':
            categorySpecificParameters.kw_capacity = parseInt(values.kw_capacity);
            break;
        default:
            break;
    }

    return {
        ...baseAction,
        parameters: categorySpecificParameters
    };
};

const ActionModal = ({
    showActionModal,
    onCloseActionModal,
    consumptionTypes,
    selectedPlan,
    onActionSave,
    onCalculateImpact,
    possibleActions,
    editingAction = null
}) => {
    const [addActionForm] = Form.useForm();
    const [isSynced, setIsSynced] = useState(true);
    const [calculatedImpact, setCalculatedImpact] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [genericFormCheck, setGenericFormCheck] = useState(false);

    // Derive available energy categories based on selected action
    const availableEnergyCategories = useMemo(() => {
        if (!selectedCategory || !possibleActions) return [];

        const selectedActionType = possibleActions.find(
            action => action.category === selectedCategory
        );

        if (!selectedActionType || !selectedActionType.energy_categories) return [];

        return consumptionTypes.filter(type =>
            selectedActionType.energy_categories.includes(type.category)
        );
    }, [selectedCategory, possibleActions, consumptionTypes]);

    useEffect(() => {
        if (editingAction) {
            addActionForm.setFieldsValue({
                category: editingAction.category,
                description: editingAction.description,
                start_date: dayjs(editingAction.start_date, 'YYYY-MM-01'),
                cost: editingAction.cost,
                energy_category: editingAction.parameters?.energy_category,
                percentage_reduction: editingAction.parameters?.percentage_reduction,
                replacement_percentage: editingAction.parameters?.replacement_percentage,
                kw_capacity: editingAction.parameters?.kw_capacity,
            });
            setGenericFormCheck(editingAction.description && editingAction.start_date && editingAction.category);
            setSelectedCategory(editingAction.category);
            setCalculatedImpact(editingAction.impacts);
        }
    }, [editingAction, addActionForm]);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        setCalculatedImpact(null);
    };

    // Create debounced calculate impact function
    const debouncedCalculateImpact = debounce(async (impact, planId) => {
        try {
            const response = await onCalculateImpact(impact, planId);
            setIsSynced(true);
            setCalculatedImpact(response);
        } catch (error) {
            console.error('Failed to calculate impact:', error);
        }
    }, 2000);

    useEffect(() => {
        return () => {
            debouncedCalculateImpact.cancel();
        };
        //eslint-disable-next-line
    }, []);

    const handleFormValuesChange = async (changedValues, allValues) => {
        const requiredFields = getFormShape(allValues.category);
        const hasAllRequiredFields = requiredFields.every(field => allValues[field]);
        const changedKey = Object.keys(changedValues)[0];
        const noImpactCategories = ['category', 'description', 'start_date', 'cost']

        if (selectedCategory !== 'GENERIC' && hasAllRequiredFields) {
            if (!calculatedImpact || (calculatedImpact && !noImpactCategories.includes(changedKey))) {
                setIsSynced(false);
                const impact = createNewAction(allValues);
                debouncedCalculateImpact(impact, selectedPlan.id);
            }
        }

        if (selectedCategory === 'GENERIC') {
            setGenericFormCheck(hasAllRequiredFields)
        }
    };

    const handleOk = async () => {
        try {
            const values = await addActionForm.validateFields();
            const newAction = createNewAction(values, calculatedImpact);
            await onActionSave(newAction);
            handleCancel();
        } catch (error) {
            console.error('Failed to add action:', error);
        }
    };

    const handleCancel = () => {
        onCloseActionModal();
        addActionForm.resetFields();
        setCalculatedImpact(null);
        setSelectedCategory(null);
    };

    const handleMonthlyEnergyChange = (index, monthIndex, value) => {
        const newCategories = calculatedImpact.map((item, i) => {
            if (i === index) {
                const updatedEnergyImpact = [...item.energy_impact];
                updatedEnergyImpact[monthIndex] = value;
                return { ...item, energy_impact: updatedEnergyImpact };
            }
            return item;
        });
        setCalculatedImpact(newCategories);
    };

    const handleAnnualEnergyChange = (index, value) => {
        const newCategories = calculatedImpact.map((item, i) => {
            if (i === index) {
                if (value === null) {
                    return { ...item, annualValue: null, energy_impact: netZeroUtils.allMonthsNull() };
                } else {
                    return { ...item, annualValue: value, energy_impact: netZeroUtils.distributeOverMonths(value) };
                }
            }
            return item;
        });
        setCalculatedImpact(newCategories);
    };

    const handleTypeChange = (selectedTypes) => {
        // Update the calculatedImpact state based on the selected types
        const updatedEnergyCategories = selectedTypes.map(type => {
            const existingCategory = calculatedImpact?.find(cat => cat.energy_category === type);
            return existingCategory || {
                description: '',
                energy_category: type,
                energy_impact: netZeroUtils.allMonthsNull(),

                annualValue: 0,
                mode: 'annual',
            };
        });

        setCalculatedImpact(updatedEnergyCategories);
    };

    const toggleMode = (category) => {
        setCalculatedImpact(prevImpact => prevImpact.map(item => {
            if (item.energy_category === category) {
                const currentMode = item.currentMode || 'annual';
                const newMode = currentMode === 'annual' ? 'monthly' : 'annual';
                let updatedItem = { ...item, currentMode: newMode };
                const allNull = netZeroUtils.isAllNull(item.energy_impact);

                if (newMode === 'annual') {
                    const totalAnnualValue = netZeroUtils.sum(item.energy_impact);
                    updatedItem = { ...updatedItem, annualValue: allNull ? null : totalAnnualValue };
                } else if (newMode === 'monthly') {
                    if (allNull) {
                        updatedItem = { ...updatedItem, energy_impact: netZeroUtils.distributeOverMonths(item.annualValue) };
                    }
                }

                return updatedItem;
            }
            return item;
        }));
    };

    const clearCategoryValues = (category) => {
        setCalculatedImpact(prevImpact => prevImpact.map(item => {
            if (item.energy_category === category) {
                return {
                    ...item,
                    energy_impact: netZeroUtils.allMonthsNull(),
                    annualValue: 0
                };
            }
            return item;
        }));
    };

    // Handler to update the description of a specific impact item
    const handleDescriptionChange = (index, newDescription) => {
        setCalculatedImpact(prevImpact => prevImpact.map((item, i) => {
            if (i === index) {
                return { ...item, description: newDescription };
            }
            return item;
        }));
    };

    const renderCategorySpecificFields = (category) => {
        switch (category) {
            case 'GENERIC':
                return (
                    <Col span={12}>
                        <Row justify="start" align="middle" style={{ marginBottom: '8px' }}>
                            <Col>
                                <p style={{ marginBottom: '0', marginRight: '8px' }}>Energy Categories and Impacts</p>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Select Energy Types"
                            style={{ marginBottom: '16px' }}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select types to add/remove"
                                onChange={handleTypeChange}
                                value={calculatedImpact?.map(item => item.energy_category)}
                                options={ComponentUtils.generateSelectOptions(
                                    availableEnergyCategories,
                                    (type) => type.category,
                                    (type) => type.description
                                )}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        {calculatedImpact?.map((item, index) => {
                            return (
                                <Row gutter={16} key={index} className='mg-b-20' style={{ border: '1px solid #e0e0e0', background: '#eee', padding: '10px', borderRadius: '12px' }}>
                                    <Col className='mg-b-10' span={24} >
                                        <Row justify='space-between' align='middle'>
                                            <Col>
                                                <Typography.Title level={5}>
                                                    {availableEnergyCategories.find(type => type.category === item.energy_category).description}
                                                </Typography.Title>
                                            </Col>
                                            <Col style={{ display: 'flex', gap: '10px' }}>
                                                <Button size="small" onClick={() => toggleMode(item.energy_category)}>
                                                    {item?.currentMode === 'monthly' ? 'Switch to Annual' : 'Switch to Monthly'}
                                                </Button>
                                                <Button size="small" onClick={() => clearCategoryValues(item.energy_category)}>Clear</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item
                                            label={`Impact Description`}
                                            required
                                            rules={[{ required: true, message: 'Please enter a description for the impact!' }]}
                                        >
                                            <Input
                                                placeholder='Description of the impact'
                                                value={item.description}
                                                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {item?.currentMode === 'monthly' ? (
                                        <Col span={24}>
                                            <Row gutter={16}>
                                                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, i) => (
                                                    <Col span={6} key={i}>
                                                        <Form.Item
                                                            label={month}
                                                            required
                                                        >
                                                            <InputNumber
                                                                placeholder={'kWh'}
                                                                value={item.energy_impact[i] !== null ? item.energy_impact[i] : null}
                                                                onChange={(value) => handleMonthlyEnergyChange(index, i, value)}
                                                                controls={false}
                                                                onFocus={(e) => e.target.select()}
                                                                formatter={GeneralUtils.getFormattedNumber}
                                                                precision={0}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    ) : (
                                        <Col span={12}>
                                            <Form.Item label={`Annual Impact (kWh):`} required>
                                                <InputNumber
                                                    placeholder="Annual Energy Impact (kWh)"
                                                    style={{ width: '100%' }}
                                                    value={item.annualValue || netZeroUtils.sum(item.energy_impact) || null}
                                                    onChange={(value) => handleAnnualEnergyChange(index, value)}
                                                    controls={false}
                                                    onFocus={(e) => e.target.select()}
                                                    formatter={GeneralUtils.getFormattedNumber}
                                                    precision={0}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            );
                        })}
                    </Col>
                );
            case 'ENERGY_EFFICIENCY':
                return (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="energy_category"
                                label="Energy Category"
                                rules={[{ required: true, message: 'Please select an energy category!' }]}
                            >
                                <Select showSearch options={ComponentUtils.generateSelectOptions(
                                    availableEnergyCategories,
                                    (item) => item.category,
                                    (item) => item.description
                                )} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="percentage_reduction"
                                label="Reduction Percentage"
                                rules={[
                                    { required: true, message: 'Please enter the reduction percentage!' },
                                    { type: 'number', min: 1, max: 100, message: 'Reduction percentage must be between 1 and 100' }
                                ]}
                            >
                                <Input
                                    type="number"
                                    min={1}
                                    max={100}
                                    placeholder="1-100"
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value > 100) {
                                            addActionForm.setFieldsValue({ percentage_reduction: 100 });
                                        } else {
                                            addActionForm.setFieldsValue({ percentage_reduction: value });
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            case 'HEAT_PUMP':
                return (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="replacement_percentage"
                                label="Replacement Percentage"
                                rules={[
                                    { required: true, message: 'Please enter the replacement percentage!' },
                                    { type: 'number', min: 1, max: 100, message: 'Replacement percentage must be between 1 and 100!' }
                                ]}
                            >
                                <Input
                                    type="number"
                                    min={1}
                                    max={100}
                                    placeholder="1-100"
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value > 100) {
                                            addActionForm.setFieldsValue({ replacement_percentage: 100 });
                                        } else {
                                            addActionForm.setFieldsValue({ replacement_percentage: value });
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            case 'SOLAR_PV':
            case 'WIND_TURBINES':
                return (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="kw_capacity"
                                label="KW Capacity"
                                rules={[{ required: true, message: 'Please enter the KW capacity!' }]}
                            >
                                <Input type="number" placeholder="KW Capacity" />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            default:
                return null;
        }
    };

    const isButtonDisabled = () => {
        if (selectedCategory !== 'GENERIC') {
            return !(isSynced && calculatedImpact)
        } else if (selectedCategory === 'GENERIC') {
            const hasAllRequiredFields = calculatedImpact && calculatedImpact.length && calculatedImpact.every(item => item.description && item.energy_impact.every(val => val !== null));
            return !hasAllRequiredFields || !genericFormCheck;
        }
    }

    const getCalculatedImpactCollapse = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const collapseItems = calculatedImpact.map((impact, index) => {
            const firstHalfColumns = months.slice(0, 6).map((month, i) => ({
                title: month,
                dataIndex: `month${i}`,
                key: `month${i}`,
                width: 70,
                render: (value) => GeneralUtils.getFormattedNumberWithUnit(value, ' kWh')
            }));

            const secondHalfColumns = months.slice(6).map((month, i) => ({
                title: month,
                dataIndex: `month${i + 6}`,
                key: `month${i + 6}`,
                width: 70,
                render: (value) => GeneralUtils.getFormattedNumberWithUnit(value, ' kWh')
            }));

            const totalChange = netZeroUtils.sum(impact.energy_impact);
            const energyCategoryDesc = consumptionTypes.find(type =>
                type.category === impact.energy_category
            ).description;

            const data = [
                {
                    key: `${index}-1`,
                    ...impact.energy_impact.slice(0, 6).reduce((acc, value, i) => ({
                        ...acc,
                        [`month${i}`]: value || 0
                    }), {})
                },
                {
                    key: `${index}-2`,
                    ...impact.energy_impact.slice(6).reduce((acc, value, i) => ({
                        ...acc,
                        [`month${i + 6}`]: value || 0
                    }), {})
                }
            ];

            return {
                key: index,
                label: `${energyCategoryDesc} - ${impact.description} - Monthly Breakdown`,
                children: (
                    <>
                        <Statistic
                            title="Annual Change"
                            value={GeneralUtils.getFormattedNumberWithUnit(totalChange, ' kWh')}
                            valueStyle={{
                                color: totalChange < 0 ? '#3f8600' : '#cf1322',
                                fontSize: '16px',
                                marginBottom: '16px'
                            }}
                        />
                        <div>
                            <Table
                                columns={firstHalfColumns}
                                dataSource={[data[0]]}
                                size="small"
                                pagination={false}
                            />
                        </div>
                        <div>
                            <Table
                                columns={secondHalfColumns}
                                dataSource={[data[1]]}
                                size="small"
                                pagination={false}
                            />
                        </div>
                    </>
                )
            };
        });

        return <Collapse items={collapseItems} />;

    }

    return (
        <Modal
            title={editingAction ? "Edit Action" : "Add New Action"}
            open={showActionModal}
            okText={isSynced ? (editingAction ? "Save Changes" : "Add Action") : "Calculating..."}
            okButtonProps={{ disabled: isButtonDisabled() }}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose
            width={1000}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form
                        form={addActionForm}
                        layout="vertical"
                        onValuesChange={handleFormValuesChange}
                    >
                        <Form.Item
                            name="category"
                            label="Action Category"
                            rules={[{ required: true, message: 'Please select an action category!' }]}
                        >
                            <Select
                                onChange={handleCategoryChange}
                                placeholder="Select an action category"
                                allowClear
                                showSearch
                                options={ComponentUtils.generateSelectOptions(
                                    possibleActions,
                                    (action) => action.category,
                                    (action) => action.description
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[{ required: true, message: 'Please enter a description for the action!' }]}
                        >
                            <Input type='text' placeholder="Description of Action" />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="start_date"
                                    label="Start Date"
                                    rules={[{ required: true, message: 'Please select the start date!' }]}
                                >
                                    <DatePicker
                                        picker="month"
                                        style={{ width: '100%' }}
                                        placeholder="Start month"
                                        disabledDate={(current) => {
                                            const isBeforeBase = current.isBefore(dayjs(`${selectedPlan.base_year + 1}-01-01`));
                                            const isAfterLastTarget = current.isAfter(dayjs(`${selectedPlan.target_year}-12-31`));

                                            return isBeforeBase || isAfterLastTarget;
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cost"
                                    label="Cost"
                                    rules={[{ required: false }]}
                                >
                                    <InputNumber 
                                        placeholder="Cost of Action" 
                                        style={{ width: '100%' }}
                                        formatter={GeneralUtils.getFormattedNumber}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {selectedCategory !== 'GENERIC' && renderCategorySpecificFields(selectedCategory)}
                    </Form>
                </Col>

                {selectedCategory === 'GENERIC' ? renderCategorySpecificFields(selectedCategory) : (<Col span={12}>
                    <div>Calculated Impact: </div>
                    <div style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        padding: '16px',
                        maxHeight: '650px',
                        minHeight: '300px',
                        overflowY: 'auto',
                        display: 'block',
                        marginBottom: '16px',
                        width: '100%',
                        marginTop: '7px',
                        position: 'relative'
                    }}>
                        <Spin size='large' spinning={!isSynced} style={{ position: 'absolute', right: '20px', top: '25px' }} />
                        {calculatedImpact && (
                            <>
                                <div style={{ marginBottom: '16px', position: 'relative' }}>
                                    <strong>Annual Impact:</strong>
                                    {(() => {
                                        const totalAllImpacts = netZeroUtils.sum(calculatedImpact.map(impact => netZeroUtils.sum(impact.energy_impact)));
                                        return (
                                            <Statistic
                                                value={GeneralUtils.getFormattedNumberWithUnit(totalAllImpacts, ' kWh')}
                                                valueStyle={{
                                                    color: totalAllImpacts < 0 ? '#3f8600' : '#cf1322',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        );
                                    })()}
                                </div>
                                {getCalculatedImpactCollapse()}
                            </>
                        )}
                    </div>
                </Col>)}
            </Row>
        </Modal>
    );
};

export default ActionModal;
