import React, { useState, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Constants from '../../../../constants';
import percentIcon from '../../../../assets/icons/percent.png';
import numbersIcon from '../../../../assets/icons/123.png';

const CHART_HEIGHT = '100%';

const EmissionsBreakdown = ({ data, loading = false, error = null, energyCategories = [], selectedTargetYear, selectedScope }) => {

    const [series, setSeries] = useState([]);
    const [showPercentage, setShowPercentage] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!data) return;
        const scopeData = data[selectedScope];
        if (!scopeData) {
            setSeries([])
            return
        }


        const series = [];
        scopeData.forEach(year => {
            const values = year.values;
            for (const key in values) {
                // Check for values greater than 0 and not already in the series
                if (values[key] > 0 && !series.includes(key)) {
                    series.push(key);
                }
            }
        })

        const seriesData = series.map((key, index) => {
            const name = energyCategories.find(c => c.category === key)?.description
            const color = Constants.ENERGY_TYPE_COLORS[key]?.color;

            return {
                name: name || key,
                data: scopeData
                    .filter(year => year.year <= selectedTargetYear)
                    .map(item => [item.year, item.values[key] / 1000]),
                color: color || Highcharts.getOptions().colors[index],
            }
        })

        setSeries(seriesData);
    }, [data, energyCategories, selectedTargetYear, selectedScope]);

    const getStackedAreaChart = () => {
        const chartOptions = {
            chart: {
                type: 'areaspline',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                minPadding: 0,
                labels: {
                    style: {
                        fontSize: '10px'
                    }
                }
            },
            yAxis: {
                title: {
                    text: showPercentage ? '% of Total Emissions' : 'tCO₂e'
                }
            },
            tooltip: {
                shared: true,
                xDateFormat: '%Y',
                pointFormat: showPercentage
                    ? '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.percentage:.1f}%</b><br/>'
                    : '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f} tCO₂e</b><br/>',
            },
            plotOptions: {
                areaspline: {
                    pointPlacement: 'on',
                    stacking: showPercentage ? 'percent' : 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        enabled: false
                    }
                },
                series: {
                    label: {
                        style: {
                            fontSize: '1.4em',
                            opacity: 0.8
                        }
                    }
                }
            },
            series: series,
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "downloadPNG",
                            "downloadPDF",
                            "downloadSVG",
                            "downloadCSV",
                            "downloadXLS",
                        ]
                    },
                    percentageToggleButton: {
                        titleKey: 'togglePercentage',
                        symbol: `url(${showPercentage ? numbersIcon : percentIcon})`,
                        symbolX: 20,
                        symbolY: 18.5,
                        onclick: function () {
                            setShowPercentage(!showPercentage);
                        },
                    },
                }
            },
        };

        return <HighchartsReact
            highcharts={Highcharts}
            ref={chartRef}
            options={chartOptions}
            containerProps={{ style: { height: CHART_HEIGHT } }}
        />
    }

    if (series.length === 0 && loading) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></div>
    }

    if (error) {
        return <div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={4}>No data available</Typography.Title>
            <Typography.Text>Please make sure your consumption data is up to date.</Typography.Text>
        </div>
    }

    if (data && data[selectedScope] === null && series.length === 0) {
        return (<div style={{ height: CHART_HEIGHT, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={4}>No data available for selected scope.</Typography.Title>
        </div>)
    }

    if (series.length === 0) {
        return null
    }

    return getStackedAreaChart()


}

export default EmissionsBreakdown;

