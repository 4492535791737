const ENVIRONMENTS = {
  localhost: {
    WebSocketUrl: "wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619",
    APIUrl: "http://localhost:8080/webapis/",
    ProcessorUrl: "http://localhost:8080/processor/",
    EngineUrl: "http://localhost:8080/engines/",
    UIUrl: "http://localhost:3000/",
    NewUIUrl: "http://localhost:3001"
  },
  dev: {
    WebSocketUrl: "wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619",
    APIUrl: "https://dev-apis.opnbuildings.com/webapis/",
    ProcessorUrl: "https://dev-processor.opnbuildings.com/processor/",
    EngineUrl: "https://dev-engines.opnbuildings.com/engines/",
    UIUrl: "https://dev-ui.opnbuildings.com/",
    NewUIUrl: "https://dev-new-ui.opnbuildings.com"
  },
  prod: {
    WebSocketUrl: "wss://mq.opnbuildings.com:61619",
    APIUrl: "https://apis-env.opnbuildings.com/webapis/",
    ProcessorUrl: "https://processor.opnbuildings.com/processor/",
    EngineUrl: "https://engines.opnbuildings.com/engines/",
    UIUrl: "https://ui.opnbuildings.com/",
    NewUIUrl: "https://new-ui.opnbuildings.com"
  }
};

const getEnvironment = () => {
  if (window.location.host.includes("localhost")) return "localhost";
  if (window.location.href.includes("dev-ui")) return "dev";
  return "prod";
};

const urlManager = {
  getWebSocketUrl: () => ENVIRONMENTS[getEnvironment()].WebSocketUrl,

  getAPIUrl: () => ENVIRONMENTS[getEnvironment()].APIUrl,

  getProcessorUrl: () => ENVIRONMENTS[getEnvironment()].ProcessorUrl,

  getEngineUrl: () => ENVIRONMENTS[getEnvironment()].EngineUrl,

  getUIUrl: () => ENVIRONMENTS[getEnvironment()].UIUrl,

  getNewUIUrl: () => ENVIRONMENTS[getEnvironment()].NewUIUrl,

  addQuery: (searchString, query) => 
    searchString.includes(query) 
      ? searchString.replace("&" + query, "") 
      : searchString + "&" + query
};

export default urlManager;



