import React, { useRef, useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import styles from './DataExplorerChart.module.scss';
import _ from 'lodash';
import moment from 'moment';
import JSZip from 'jszip';

import { Icon } from '../../../../components/Icon';
import refreshIcon from '../../../../assets/icons/refresh.png';
import swapIcon from '../../../../assets/icons/swap.png';
import ChartUtils from '../../../../utils/ChartUtils';
import Constants from "./../../../../constants";

// Initialize Highcharts customizations
ChartUtils.initializeDataExplorerHighchartsConfig();

const HIGHCHARTS_DATE_FORMAT = '%A, %b %e, %Y %H:%M';
const HEAVY_DATA_LOAD_THRESHOLD = 1000;

const DataExplorerChart = ({ data, selectedProperties, onRowSelectionChange, cardOpen, loading, timeframeTitle, granularity, refreshData, hasComparison }) => {
    const chartRef = useRef(null);
    const [colors, setColors] = useState({});
    const [axisSwapped, setAxisSwapped] = useState(false);
    const [hovered, setHovered] = useState(null);

    const [chartOptions, setChartOptions] = useState({});

    const getUnitFromDataType = (dataType) => {
        if (dataType === "tempC") return { label: "Temperature", unit: "°C", decimals: 1 };
        if (dataType === "percent") return { label: "Percent", unit: "%", decimals: 2 };
        if (dataType === "kwh") return { label: "Kilowatt Hours", unit: " kWh", decimals: 2 };
        if (dataType === "kw") return { label: "Kilowatts", unit: " kW", decimals: 2 };
        if (dataType === "degDays") return { label: "Degree Days", unit: "", decimals: 2 };
        if (dataType === "kvarh") return { label: "Reactive Power", unit: " kVArh" };
        if (dataType === "pascals") return { label: "Pressure", unit: " Pa" };
        if (dataType === "ppm") return { label: "CO2", unit: " ppm", decimals: 0 };
        if (dataType === "level") return { label: "Level", unit: "" };
        if (dataType === "decimal") return { label: "Cost", unit: "" };
        if (dataType === "lux") return { label: "Illuminance", unit: ' lx' };
        if (dataType === "m3") return { label: "Volume", unit: ' m3' };
        if (dataType === "integer") return { label: "Count", unit: '' };
        if (dataType === "pf") return { label: "Power Factor", unit: ' PF' };
        if (dataType === "pulse") return { label: "Pulse", unit: '' };
        if (dataType === "ugm3") return { label: "Pollutant Concentration", unit: ' µg/m3' };

        return {
            label: dataType,
            unit: ''
        }
    }

    // Timestamp identifier that allows alignment of series in comparison mode
    const createTimestampIdentifier = (timestamp, index = null) => {
        if (granularity === 'DAY') return moment(timestamp).format('HHmm')
        if (granularity === 'WEEK') return moment(timestamp).format('EHHmm')
        if (granularity === 'MONTH') return moment(timestamp).format('DDHHmm')
        if (granularity === 'YEAR') return moment(timestamp).format('DDDHHmm')
        if (granularity === 'CUSTOM') return index + moment(timestamp).format('HHmm')
    }

    const zipAndDownloadSeparateCSV = async (series) => {
        const zip = new JSZip();
        const csvFolder = zip.folder("explorer_chart_data");

        const [selectedPeriod, comparisonPeriod] = timeframeTitle
            .split(' and ')
            .map(period => period.replace(/\s*\([^)]*\)/g, ''))
            .map(period => period.replace(/\s+/g, '').toLowerCase());

        // Separate series into current and comparison periods
        const currentPeriodSeries = series.filter(s => !s.isComparison);
        const comparisonPeriodSeries = series.filter(s => s.isComparison);

        // Helper function to process series into CSV content
        const processSeriesGroup = (seriesGroup) => {
            const timestampMap = new Map();

            // Column headers as keys
            const columnHeaders = seriesGroup.map(s =>
                `${s.entity?.name || 'UNKNOWN ASSET'} | ${s.building?.name || 'UNKNOWN BUILDING'} | ${s.property || 'UNKNOWN PROPERTY'}`
            );

            // Data points
            seriesGroup.forEach((s, columnIndex) => {
                s.data.forEach(point => {
                    const timestamp = moment(point.x).format('YYYY-MM-DD HH:mm:ss');
                    if (!timestampMap.has(timestamp)) {
                        // Initialize array with nulls for all columns
                        timestampMap.set(timestamp, new Array(seriesGroup.length).fill(null));
                    }
                    if (point.y !== null) {
                        timestampMap.get(timestamp)[columnIndex] = point.y;
                    }
                });
            });

            // Convert to CSV content
            const headers = ['Timestamp', ...columnHeaders];
            const rows = [...timestampMap.entries()]
                .sort(([timestampA], [timestampB]) => timestampA.localeCompare(timestampB))
                .map(([timestamp, values]) =>
                    [timestamp, ...values.map(v => v !== null ? v.toString() : '')]
                );

            return [headers, ...rows].map(row => row.join(',')).join('\n');
        };

        // Create CSVs for both periods
        if (currentPeriodSeries.length > 0) {
            const currentPeriodCSV = processSeriesGroup(currentPeriodSeries);
            csvFolder.file(`${selectedPeriod}.csv`, currentPeriodCSV);
        }

        if (comparisonPeriodSeries.length > 0) {
            const comparisonPeriodCSV = processSeriesGroup(comparisonPeriodSeries);
            csvFolder.file(`${comparisonPeriod}.csv`, comparisonPeriodCSV);
        }

        try {
            // Generate zip file
            const content = await zip.generateAsync({ type: "blob" });

            // Create download link
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `chart_data_${moment().format('YYYY-MM-DD_HH-mm')}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error creating zip file:', error);
        }
    };

    useEffect(() => {
        const newColors = { ...colors };
        const chartSeries = _.get(chartRef, 'current.chart.series', []);
        const usedColors = new Set(chartSeries.map(series => series.color));
        const availableColors = Constants.CHART_COLORS.filter(color => !usedColors.has(color));

        const selectedKeys = new Set(selectedProperties.map(p => `${p.property.property_id}-${p.entity.entity_type_id}`));

        Object.keys(newColors).forEach(key => {
            if (!selectedKeys.has(key)) {
                delete newColors[key];
            }
        });

        selectedProperties.forEach((p) => {
            const key = `${p.property.property_id}-${p.entity.entity_type_id}`;
            if (!newColors[key]) {
                if (availableColors.length > 0) {
                    newColors[key] = availableColors.shift();
                } else {
                    newColors[key] = Highcharts.getOptions().colors[Object.keys(newColors).length % Highcharts.getOptions().colors.length];
                }
            }
        });

        setColors(newColors);
        // eslint-disable-next-line
    }, [selectedProperties, chartRef.current]);

    useEffect(() => {
        // Cleanup function to remove iframe on unmount
        return () => {
            const iframe = document.getElementById('hc-export-frame');
            if (iframe) {
                iframe.parentNode.removeChild(iframe);
            }
        };
    }, []);

    useEffect(() => {
        const uniqueDataTypes = [...new Set(data.map(item => item.property.data_type))];

        const getYAxes = uniqueDataTypes.map((dataType) => {

            const unit = getUnitFromDataType(dataType).unit;
            const label = getUnitFromDataType(dataType).label;
            const isPrimary = dataType === uniqueDataTypes[0];

            return {
                title: {
                    text: label,
                },
                opposite: axisSwapped ? isPrimary : !isPrimary,
                labels: {
                    format: '{value}' + unit,
                }
            };
        })

        let pointNames = [];
        let comparisonMode = false;
        let timestamps = [];
        let comparisonTimestamps = [];
        let heavyDataLoad = false;

        let series = data.map((item) => {

            const key = `${item.property.property_id}-${item.entity_type.entity_type_id}`;
            const color = colors[key];

            // Determine if the series is for the comparison timeframe
            const isComparison = pointNames.includes(key)
            if (isComparison) comparisonMode = true;
            pointNames.push(key);

            // List of dates for custom alignment, first digit being the index of the date of the timestamp
            const dates = [...new Set(item.data.map((dataItem) => moment(dataItem.timestamp).format('YYYY-MM-DD')))];

            const seriesData = item.data.map((dataItem) => {
                const valueOf = moment(dataItem.timestamp).valueOf();
                if (!hasComparison) {
                    return [valueOf, dataItem.value]
                }

                let uniqueIdentifier = createTimestampIdentifier(dataItem.timestamp);
                if (granularity === 'CUSTOM') {
                    const index = dates.indexOf(moment(dataItem.timestamp).format('YYYY-MM-DD'));
                    uniqueIdentifier = createTimestampIdentifier(dataItem.timestamp, index)
                }

                const obj = {
                    label: uniqueIdentifier,
                    x: moment(dataItem.timestamp).valueOf(),
                    y: dataItem.value,
                }

                if (isComparison) {
                    if (!comparisonTimestamps.some(e => e.label === obj.label)) {
                        comparisonTimestamps.push(obj)
                    }
                } else {
                    if (!timestamps.some(e => e.label === obj.label)) {
                        timestamps.push(obj)
                    }
                }

                return {
                    label: uniqueIdentifier,
                    x: valueOf,
                    y: dataItem.value,
                }

            });

            if (seriesData.length > HEAVY_DATA_LOAD_THRESHOLD) heavyDataLoad = true;

            const label = getUnitFromDataType(item.property.data_type).label;
            const yAxisIndex = getYAxes.findIndex((yAxis) => yAxis.title.text === label);

            return {
                name: item.entity_type.name,
                property: item.property.name,
                building: item.building,
                entity: item.entity_type,
                data: seriesData,
                type: "spline",
                yAxis: yAxisIndex,
                xAxis: isComparison ? 1 : 0, // Use the second X axis for comparison
                color: color,
                isComparison: isComparison,
                dashStyle: isComparison ? 'Dash' : 'Solid', // Dashed line for comparison
                property_id: item.property.property_id,
                entity_type_id: item.entity_type.entity_type_id,
                visible: hovered === null || hovered === key,
                uqkey: key,
                boostThreshold: HEAVY_DATA_LOAD_THRESHOLD,
                tooltip: {
                    valueSuffix: getUnitFromDataType(item.property.data_type).unit,
                    valueDecimals: getUnitFromDataType(item.property.data_type).decimals,
                }
            };
        });

        //  xAxis alignment
        if (comparisonMode) {
            // Grabbing all comparison and noncomparison timestamps
            const sortedTimestamps = timestamps.map(t => ({ label: t.label, y: t.y, x: t.x }))
            const sortedComparisonTimestamps = comparisonTimestamps.map(t => ({ label: t.label, y: t.y, x: t.x }))

            // Finding differing elements between comp and noncomp series
            const differingElements = [..._.differenceBy(sortedTimestamps, sortedComparisonTimestamps, 'label'), ..._.differenceBy(sortedComparisonTimestamps, sortedTimestamps, 'label')];

            // cloning them
            let backFilledTimestamps = [...sortedTimestamps];
            let backFilledComparisonTimestamps = [...sortedComparisonTimestamps];

            // backfilling the missing elements with 'tbc' as timestamp (to be calculated)
            differingElements.forEach(element => {
                if (!backFilledTimestamps.some(e => e.label === element.label)) {
                    backFilledTimestamps.push({ label: element.label, y: null, x: 'tbc' })
                }

                if (!backFilledComparisonTimestamps.some(e => e.label === element.label)) {
                    backFilledComparisonTimestamps.push({ label: element.label, y: null, x: 'tbc' })
                }
            })

            // ordering
            backFilledTimestamps = _.orderBy(backFilledTimestamps, 'label');
            backFilledComparisonTimestamps = _.orderBy(backFilledComparisonTimestamps, 'label');

            // Creating diff gap that will help extrapolate ts, difference between aligned comp and noncomp points is constant
            let diffGap;
            sortedTimestamps.forEach((v, i) => {
                try {

                    const target = sortedComparisonTimestamps.find(e => e.label === v.label);
                    const diff = v.x - target.x;

                    if (diff) {
                        diffGap = diff;
                    }
                } catch {
                }
            })

            // taking all the 'tbc' values and backfilling them with the diff gap and filling them with opposite series (comp/noncomp)
            backFilledTimestamps = backFilledTimestamps.map((v) => {
                if (v.x === 'tbc') {
                    const correspondingData = backFilledComparisonTimestamps.find(e => e.label === v.label);
                    const x = correspondingData.x + diffGap

                    return {
                        ...v,
                        x,
                    }
                }
                return {
                    ...v,
                }
            })

            backFilledComparisonTimestamps = backFilledComparisonTimestamps.map((v) => {
                if (v.x === 'tbc') {
                    const correspondingData = backFilledTimestamps.find(e => e.label === v.label);
                    const x = correspondingData.x - diffGap
                    return {
                        ...v,
                        x,
                    }
                }
                return {
                    ...v
                }
            })

            // ordering chronologically
            backFilledTimestamps = _.orderBy(backFilledTimestamps, 'x');
            backFilledComparisonTimestamps = _.orderBy(backFilledComparisonTimestamps, 'x');

            // building series with aligned and backfilled timestamps
            const alignedSeries = series.map(s => {
                const srcArr = s.isComparison ? backFilledComparisonTimestamps : backFilledTimestamps;

                return {
                    ...s,
                    data: srcArr.map((src) => {
                        const target = s.data.find(e => e.label === src.label);

                        return {
                            ...target,
                            x: target ? target.x : src.x
                        }
                    }),

                }
            })

            series = alignedSeries;

        }

        const yAxes = getYAxes;
        const hasTwoYAxes = yAxes.length === 2;

        const options = {
            chart: {
                zoomType: "xy",
                animation: heavyDataLoad ? false : true,
            },
            boost: {
                enabled: heavyDataLoad,
                useGPUTranslations: true,
                usePreAllocated: true,
            },
            title: {
                text: timeframeTitle,
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Archivo Semibold, sans-serif',
                }
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            xAxis: [
                {
                    gridLineDashStyle: "longdash",
                    type: "datetime",
                    gridLineWidth: 1,
                    gridLineColor: "#e6e6e6",
                    minorLineWidth: 1,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: "#343a40",
                            fontSize: "9px",
                        },
                    },
                    plotBands: [],
                },
                {
                    gridLineDashStyle: "longdash",
                    type: "datetime",
                    gridLineWidth: 1,
                    gridLineColor: "#e6e6e6",
                    minorLineWidth: 1,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: "#343a40",
                            fontSize: "9px",
                        },
                    },
                    plotBands: [],
                    opposite: true,
                }
            ],
            yAxis: yAxes,
            tooltip: {
                shared: true,
                useHTML: true,
                xDateFormat: HIGHCHARTS_DATE_FORMAT,
                formatter: hasComparison ? function () {
                    let tooltip = `<table style="border-spacing: 0 10px;"><thead><tr><th>Point</th>`;
                    const dates = this.points.map(point => Highcharts.dateFormat(HIGHCHARTS_DATE_FORMAT, point.x));
                    const uniqueDates = [...new Set(dates)];
                    uniqueDates.forEach(date => {
                        tooltip += `<th style="padding: 0 10px;">${date}</th>`;
                    });
                    tooltip += `</tr></thead><tbody>`;

                    const groupedPoints = {};
                    this.points.forEach((point, index) => {
                        const key = point.series.userOptions.uqkey;
                        if (!groupedPoints[key]) {
                            groupedPoints[key] = [];
                        }
                        groupedPoints[key].push(point);
                    });

                    Object.keys(groupedPoints).forEach(key => {
                        const pointColor = groupedPoints[key][0].color;
                        const name = groupedPoints[key][0].series.name;
                        tooltip += `<tr><td><span style="color:${pointColor}; font-size: 15px; margin-right: 5px;">\u25CF</span> <b>${name}</b></td>`;
                        uniqueDates.forEach(date => {
                            const point = groupedPoints[key].find(p => Highcharts.dateFormat(HIGHCHARTS_DATE_FORMAT, p.x) === date);
                            if (point) {
                                let value = point.y;
                                if (point.series.tooltipOptions.valueDecimals) value = value.toFixed(point.series.tooltipOptions.valueDecimals);
                                tooltip += `<td style="padding: 0 10px;">${value} ${point.series.tooltipOptions.valueSuffix}</td>`;
                            } else {
                                tooltip += `<td style="padding: 0 10px;">-</td>`;
                            }
                        });
                        tooltip += `</tr>`;
                    });

                    tooltip += `</tbody></table>`;
                    return tooltip;
                } : null
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    marker: {
                        enabled: false,
                    },
                    groupPadding: 0,
                    pointPadding: 0,
                    borderWidth: 0.2,
                    pointPlacement: "on",
                },
                line: {
                    dataLabels: {
                        enabled: false,
                    },
                    softThreshold: false,
                    series: {
                        marker: {
                            enabled: false,
                        },
                    },
                },
                column: {
                    softThreshold: false,
                },
                area: {
                    stacking: "normal",
                    step: "right",
                    softThreshold: false,
                },
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'top',
                        symbol: 'menu',
                        x: 0,
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "downloadPNG",
                            "downloadPDF",
                            "downloadSVG",
                            // Comparison mode download splits CSVs into separate files for each timeframe
                            hasComparison ? {
                                text: 'Download CSV',
                                onclick: zipAndDownloadSeparateCSV
                            } : "downloadCSV",
                            "downloadXLS",
                        ]
                    },
                    refreshButton: {
                        titleKey: 'refreshData',
                        symbol: `url(${refreshIcon})`,
                        symbolX: 20,
                        symbolY: 18.5,
                        symbolFill: '#666666',
                        onclick: function () {
                            refreshData();  // Call the refresh function
                        }
                    },
                    swapButton: {
                        titleKey: 'swapAxes',
                        symbol: `url(${swapIcon})`,
                        symbolX: 20,
                        symbolY: 18.5,
                        symbolFill: '#666666',
                        onclick: function () {
                            setAxisSwapped(!axisSwapped);
                        },
                        _titleKey: 'swapAxes',
                        enabled: hasTwoYAxes
                    },
                },
                csv: {
                    columnHeaderFormatter: function (item) {
                        if (!item || item instanceof Highcharts.Axis) {
                            return 'Timestamp';
                        } else {
                            const asset = _.get(item, 'userOptions.entity.name', 'UNKNOWN ASSET')
                            const property = _.get(item, 'userOptions.property', 'UNKNOWN PROPERTY')
                            const building = _.get(item, 'userOptions.building.name', 'UNKNOWN BUILDING')
                            return `${asset} | ${building} | ${property}`
                        }
                    }
                }
            },
            series: series,
        };

        setChartOptions(options);
        // eslint-disable-next-line
    }, [data, cardOpen, axisSwapped, granularity, hovered]);

    const labelClickHandler = (e) => {
        setHovered(null)
        onRowSelectionChange(e)
    }

    const getChartLabels = () => {
        const chartSeries = chartOptions?.series;

        if (!chartSeries) return null;

        const sortedProperties = selectedProperties.sort((a, b) => {
            if (a.entity.entity_type_id !== b.entity.entity_type_id) {
                return a.entity.entity_type_id - b.entity.entity_type_id;
            }
            return a.property.property_id - b.property.property_id;
        });

        return (
            <div className={styles.labelWrapper}>
                {sortedProperties.map((p) => {
                    const key = `${p.property.property_id}-${p.entity.entity_type_id}`;
                    let color = '#eeeeee';
                    let bgColor = '#eeeeee33';
                    let noData;

                    if (chartSeries) {
                        const serie = chartSeries.find(
                            (s) =>
                                s.property_id === p.property.property_id &&
                                s.entity_type_id === p.entity.entity_type_id
                        );
                        if (serie) {
                            color = colors[key];
                            bgColor = color + '33';
                            noData = serie.data.length ? false : true;
                        } else {
                            noData = true;
                        }
                    }

                    const handleMouseEnter = () => {
                        setHovered(key);
                    };

                    const handleMouseLeave = () => {
                        setHovered(null)
                    };

                    const matching_dataset = data.find(
                        (d) =>
                            d.property.property_id === p.property.property_id &&
                            d.entity_type.entity_type_id === p.entity.entity_type_id
                    );
                    const property = _.get(p, 'property.name', _.get(matching_dataset, 'property.name', '-'));
                    const entity = _.get(p, 'entity.name', _.get(matching_dataset, 'entity_type.name', '-'));
                    const building = _.get(p, 'building.name', _.get(matching_dataset, 'building.name', '-'));

                    if (noData) {
                        return (
                            <div
                                key={key}
                                className={styles.label}
                                style={{ background: '#eeeeee33', borderColor: '#eeeeee' }}
                            >
                                <div className={styles.icon}>
                                    {cardOpen && (
                                        <Icon
                                            name="Close"
                                            color={'#5e656e'}
                                            size={20}
                                            onClick={() => onRowSelectionChange(p)}
                                        />
                                    )}
                                </div>
                                <div className={styles.property} style={{ paddingRight: cardOpen ? '15px' : '' }}>
                                    {property} (NO DATA)
                                </div>
                                <div className={styles.entity}>{entity}</div>
                                <div className={styles.building}>{building}</div>
                            </div>
                        );
                    }

                    return (
                        <div
                            key={key}
                            className={styles.label}
                            style={{ background: bgColor, borderColor: color }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className={styles.entity}>{entity}</div>
                            <div className={styles.building}>{building}</div>
                            <div className={styles.property} style={{ paddingRight: cardOpen ? '15px' : '0px' }}>
                                {property}
                            </div>
                            <div className={styles.icon}>
                                {cardOpen && (
                                    <Icon
                                        name="Close"
                                        color={'#5e656e'}
                                        size={20}
                                        onClick={() => labelClickHandler(p)}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const getLegend = () => {
        if (!hasComparison) return null;

        const [selectedPeriod, comparisonPeriod] = timeframeTitle
            .split(' and ')
            .map(period => period.replace(/\s*\([^)]*\)/g, ''));

        if (selectedPeriod === comparisonPeriod) return null;

        return (
            <div className={styles.customLegend}>
                <div className={styles.legendItem}>
                    <div className={styles.legendLine}></div>
                    <span>{selectedPeriod}</span>
                </div>
                <div className={styles.legendItem}>
                    <div className={`${styles.legendLine} ${styles.legendLineDashed}`}></div>
                    <span>{comparisonPeriod}</span>
                </div>
            </div>
        )
    }

    if (selectedProperties.length === 0) {
        return <div style={{
            height: '30vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
        }}>{cardOpen ? 'Select timeframe and properties to chart' : 'Edit to select timeframe and properties to chart'}</div>
    }

    if (data.length === 0 && !loading) {
        return <div style={{
            flex: 1,
            width: '100%',
        }}></div>
    }

    if (selectedProperties.length !== 0 && data.length === 0 && loading) {
        return <div style={{
            flex: 1,
            width: '100%',
        }}></div>
    }


    return <div className={styles.chartContainer}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} containerProps={{ style: { flex: 1, maxHeight: cardOpen ? '30vh' : null } }} />
        {getLegend()}
        {getChartLabels()}
    </div>;
};

export default DataExplorerChart;
