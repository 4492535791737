import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, DatePicker, Row, Col, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Icon } from '../../../../components/Icon';

const PlanModal = ({
    showPlanModal,
    onPlanSave,
    editingPlan,
    onClosePlanModal
}) => {
    const [planForm] = Form.useForm();
    const currentYear = new Date().getFullYear();
    const [targets, setTargets] = useState([]);

    useEffect(() => {
        if (editingPlan) {
            planForm.setFieldsValue({
                description: editingPlan.description,
                baseYear: dayjs().year(editingPlan.base_year),
                targetYear: dayjs().year(editingPlan.target_year),
            });
            setTargets(editingPlan.interim_targets || []);
        } else {
            planForm.resetFields();
            setTargets([]);
        }
    }, [editingPlan, planForm]);

    const prepareTargetsForSaving = () => {
        return targets
            .filter(target => target.target_year !== null)
            .sort((a, b) => a.target_year - b.target_year)
            .map(target => ({
                ...target,
                scope_one: {
                    ...target.scope_one,
                    reduction_percent: target.scope_one.type === 'NON_INCREASING_USAGE' ?
                    null : target.scope_one.reduction_percent === null ?
                    0 : target.scope_one.reduction_percent
                },
                scope_two: {
                    ...target.scope_two,
                    reduction_percent: target.scope_two.type === 'NON_INCREASING_USAGE' ?
                    null : target.scope_two.reduction_percent === null ?
                    0 : target.scope_two.reduction_percent
                }
            }));
    }

    const handleSaveList = async () => {
        try {
            const values = await planForm.validateFields();
            const sortedTargets = prepareTargetsForSaving();

            const newPlan = {
                description: values.description,
                base_year: values.baseYear.year(),
                target_year: values.targetYear.year(),
                target_year_reduction_percent: 100, // Hardcoded for now
                interim_targets: sortedTargets,
                id: editingPlan?.id
            }

            await onPlanSave(newPlan);
            setTargets([]);
            planForm.resetFields();
        } catch (error) {
            if (!error.errorFields) {
                console.error('Failed to save the list: ', error.code, error.type);
            }
        }
    };

    const addTarget = () => {
        setTargets([...targets, {
            target_year: null,
            scope_one: { type: 'REDUCTION_PERCENT', reduction_percent: null },
            scope_two: { type: 'REDUCTION_PERCENT', reduction_percent: null },
            id: targets.length + 1
        }]);
    };

    const handleTargetChange = (selectedTarget, field, value, scope = null) => {
        const newTargets = [...targets];
        const targetIndex = newTargets.findIndex(target => target.id ? target.id === selectedTarget.id : target.target_year === selectedTarget.target_year);
        if (targetIndex !== -1) {
            if (scope) {
                if (field === 'type') {
                    newTargets[targetIndex][scope][field] = value;
                    if (value === 'NON_INCREASING_USAGE') {
                        newTargets[targetIndex][scope].reduction_percent = null;
                    }
                } else if (field === 'reduction_percent') {
                    const clampedValue = Math.max(0, Math.min(100, +value));
                    newTargets[targetIndex][scope][field] = clampedValue;
                }
            } else {
                newTargets[targetIndex][field] = value;
            }
        }
        setTargets(newTargets);
    };

    const removeTarget = (target) => {
        setTargets(targets.filter(t => t.id ?
            t.id !== target.id :
            t.target_year !== target.target_year));
    };

    const handleCancel = () => {
        onClosePlanModal();
        setTargets([]);
        planForm.resetFields();
    };

    const handleToggleType = (target, scope) => {
        const newType = target[scope].type === 'REDUCTION_PERCENT' ? 'NON_INCREASING_USAGE' : 'REDUCTION_PERCENT';
        handleTargetChange(target, 'type', newType, scope);
    };

    return (
        <Modal
            open={showPlanModal}
            onCancel={handleCancel}
            title={editingPlan ? "Edit Net Zero Plan" : "Create New Net Zero Plan"}
            footer={[
                <Button
                    key="cancel"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSaveList}
                >
                    Save
                </Button>
            ]}
        >
            <Form
                form={planForm}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    description: "",
                    baseYear: dayjs('2020-01-01'),
                    targetYear: dayjs('2050-01-01'),
                }}
            >
                <Form.Item
                    name="description"
                    label="Plan Description"
                    rules={[
                        { required: true, message: 'Please enter a plan description' },
                        { whitespace: true, message: 'Description cannot be empty' }
                    ]}
                >
                    <Input placeholder="Enter plan description" />
                </Form.Item>

                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                        name="baseYear"
                        label="Base Year"
                        style={{ flex: 1 }}
                        rules={[{ required: true, message: 'Please select a base year' }]}
                    >
                        <DatePicker
                            picker="year"
                            style={{ width: '100%' }}
                            disabledDate={(current) => {
                                const year = current.year();
                                return year < 2015 || year > currentYear;
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="targetYear"
                        label="Net Zero Year"
                        style={{ flex: 1 }}
                        rules={[{ required: true, message: 'Please select a target year' }]}
                    >
                        <DatePicker
                            picker="year"
                            style={{ width: '100%' }}
                            disabledDate={(current) => {
                                const year = current.year();
                                return year < 2030 || year > 2050;
                            }}
                            cellRender={(current) => {
                                const year = current.year();
                                const isDisabled = year < 2030 || year > 2050;
                                return (
                                    <div className={`ant-picker-cell-inner ${isDisabled ? '' : 'ant-picker-cell-in-view'}`}>
                                        {year}
                                    </div>
                                );
                            }}
                        />
                    </Form.Item>
                </div>
                <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0', marginRight: '8px' }}>Interim Targets</p>
                        <Tooltip
                            title={
                                <div>
                                    <p style={{ marginBottom: '10px' }}>You can select following types of targets for each scope:</p>
                                    <strong>Percentage Reduction:</strong>
                                    <p style={{ marginBottom: '10px' }}>Relative emissions reduction from the base year.</p>
                                    <strong>Projected Emissions:</strong>
                                    <p style={{ margin: 0 }}>Projected emissions if energy consumption doesn’t change from the base year.</p>
                                </div>
                            }
                        >
                            <>
                                <Icon name="Info" color="black" />
                            </>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Button
                            type="default"
                            icon={<Icon name="AddCircle" color='black' />}
                            onClick={addTarget}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>
                {targets
                    .slice()
                    .sort((a, b) => a.target_year - b.target_year)
                    .map((target) => (
                        <div key={target.target_year || target.id} style={{ marginBottom: '16px', borderRadius: '12px', border: '1px solid #e0e0e0', background: '#eee', padding: '16px' }}>
                            <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
                                <DatePicker
                                    picker="year"
                                    style={{ width: '150px' }}
                                    value={target.target_year ? dayjs().year(target.target_year) : null}
                                    onChange={(date) => {
                                        handleTargetChange(target, 'target_year', date ? date.year() : null);
                                    }}
                                    disabledDate={(current) => {
                                        const baseYear = planForm.getFieldValue('baseYear').year();
                                        const targetYear = planForm.getFieldValue('targetYear').year();
                                        const year = current.year();
                                        const isOutOfRange = year <= baseYear || year >= targetYear;
                                        const isAlreadySelected = targets.some(t => t.target_year === year);
                                        return isOutOfRange || isAlreadySelected;
                                    }}
                                    placeholder="Select a year"
                                    cellRender={(current) => {
                                        const baseYear = planForm.getFieldValue('baseYear').year();
                                        const targetYear = planForm.getFieldValue('targetYear').year();
                                        const year = current.year();
                                        const isOutOfRange = year <= baseYear || year >= targetYear;
                                        const isAlreadySelected = targets.some(t => t.target_year === year);
                                        const isDisabled = isOutOfRange || isAlreadySelected;
                                        return (
                                            <div className={`ant-picker-cell-inner ${isDisabled ? '' : 'ant-picker-cell-in-view'}`}>
                                                {year}
                                            </div>
                                        );
                                    }}
                                />
                                <Button
                                    icon={<Icon name="Delete" color="gray" />}
                                    onClick={() => removeTarget(target)}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '16px', marginTop: '8px' }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <label style={{ margin: 0 }}>Scope One</label>
                                        <Tooltip title={target.scope_one.type === 'REDUCTION_PERCENT' ? 'Switch to Projected Emissions' : 'Switch to Percentage Reduction'}>
                                            <Button
                                                type="link"
                                                onClick={() => handleToggleType(target, 'scope_one')}
                                                icon={
                                                    <Icon
                                                        name={target.scope_one.type === 'REDUCTION_PERCENT' ? 'EnergySavingLeaf' : 'Percent'}
                                                        size={24}
                                                        color="#000"
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                    <Input
                                        type="number"
                                        min={1}
                                        max={100}
                                        value={target.scope_one.reduction_percent}
                                        onChange={(e) => handleTargetChange(target, 'reduction_percent', e.target.value, 'scope_one')}
                                        placeholder={target.scope_one.type === 'REDUCTION_PERCENT' ? 'Scope One Reduction %' : 'Projected Emissions'}
                                        style={{ marginTop: '8px', marginRight: '8px' }}
                                        disabled={target.scope_one.type === 'NON_INCREASING_USAGE'}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <label style={{ margin: 0 }}>Scope Two</label>
                                        <Tooltip title={target.scope_two.type === 'REDUCTION_PERCENT' ? 'Switch to Projected Emissions' : 'Switch to Percentage Reduction'}>
                                            <Button
                                                type="link"
                                                onClick={() => handleToggleType(target, 'scope_two')}
                                                icon={
                                                    <Icon
                                                        name={target.scope_two.type === 'REDUCTION_PERCENT' ? 'EnergySavingLeaf' : 'Percent'}
                                                        size={24}
                                                        color="#000"
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                    <Input
                                        type="number"
                                        min={1}
                                        max={100}
                                        value={target.scope_two.reduction_percent}
                                        onChange={(e) => handleTargetChange(target, 'reduction_percent', e.target.value, 'scope_two')}
                                        placeholder={target.scope_two.type === 'REDUCTION_PERCENT' ? 'Scope Two Reduction %' : 'Projected Emissions'}
                                        style={{ marginTop: '8px', marginRight: '8px' }}
                                        disabled={target.scope_two.type === 'NON_INCREASING_USAGE'}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
            </Form>
        </Modal>
    );
};

export default PlanModal;