import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { Select, Button, Typography, Tag } from "antd";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

/**
 * VirtualCalculationBuilder
 *
 * A controlled component for building meter calculations with optional parentheses and operators.
 *
 * Basic Usage:
 *  - Click a token (Tag) to remove it.
 *  - Click in the display area to move the 'Insert Here' placeholder to that position.
 *  - Use the meter dropdown or operator buttons to insert at the current placeholder index.
 *  - Reset reverts to `initValue` and places the placeholder at the formula's end.
 *  - Clear All empties the formula and places placeholder at 0.
 */
const VirtualCalculationBuilder = forwardRef(
  ({ meterOptions, initValue, value, onChange }, ref) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [insertPosition, setInsertPosition] = useState(null); // index to insert new tokens
    const containerRef = useRef(null);

    // Update the parent formula and clear any error
    const handleChange = useCallback((newValue) => {
      setErrorMessage(null);
      onChange(newValue);
    }, [onChange]);

    // Maps certain operators to more user-friendly symbols
    const getDisplayOperator = (operator) => (operator === "*" ? "×" : operator);

    // Parse the formula string into tokens
    const parseFormula = (formula) => {
      if (!formula) return [];
      const tokenRegex = /(\d+|\+|-|\*|\/|\(|\))/g;
      const rawTokens = formula.match(tokenRegex) || [];

      return rawTokens.map((token) => {
        if (["+", "-", "*", "/"].includes(token)) {
          return { type: "operator", value: token };
        }
        if (token === "(" || token === ")") {
          return { type: "paren", value: token };
        }
        // Otherwise, treat as meter ID
        const meter = meterOptions.find(m => String(m.meter.id) === String(token));
        return {
          type: "meter",
          id: token,
          label: meter ? meter.meter.name : "Unknown Meter",
        };
      });
    };

    // Convert tokens array back into a string
    const buildFormula = (tokens) =>
      tokens.map(t => (t.type === "meter" ? t.id : t.value)).join(" ");

    // Expose a validation method via ref for external use
    useImperativeHandle(ref, () => ({
      validateTokens: () => {
        if (!value) {
          setErrorMessage(null);
          return true;
        }
        const tokens = parseFormula(value);

        // Check parentheses balance
        let balance = 0;
        for (const token of tokens) {
          if (token.value === "(") balance++;
          if (token.value === ")") balance--;
          if (balance < 0) {
            setErrorMessage("A closing parenthesis ')' has no matching '('.");
            return false;
          }
        }
        if (balance > 0) {
          setErrorMessage("An opening parenthesis '(' is not closed.");
          return false;
        }

        // Basic checks on first/last tokens
        const firstToken = tokens[0];
        const lastToken = tokens[tokens.length - 1];
        if (firstToken?.type === "operator" && firstToken.value !== "(") {
          setErrorMessage("Cannot start the formula with an operator.");
          return false;
        }
        if (lastToken?.type === "operator" && lastToken.value !== ")") {
          setErrorMessage("Cannot end the formula with an operator.");
          return false;
        }

        // Check consecutive tokens for validity
        for (let i = 0; i < tokens.length - 1; i++) {
          const current = tokens[i];
          const next = tokens[i + 1];
          if (current.type === "meter" && next.type === "meter") {
            setErrorMessage("You must have an operator between meters.");
            return false;
          }
          if (
            current.type === "operator" &&
            next.type === "operator" &&
            !(current.value === ")" && next.value === "(")
          ) {
            setErrorMessage("Cannot have consecutive operators.");
            return false;
          }
          if (current.type === "meter" && next.type === "paren" && next.value === "(") {
            setErrorMessage("You must have an operator before '(' .");
            return false;
          }
        }
        setErrorMessage(null);
        return true;
      },
    }));

    // Retrieve meter name for new meter tokens
    const getMeterName = (meterId) => {
      const meter = meterOptions.find(m => String(m.meter.id) === String(meterId));
      return meter ? meter.meter.name : "Unknown Meter";
    };

    // Insert a meter token
    const handleAddMeter = (meterId, position) => {
      if (!meterId) return;
      const tokens = parseFormula(value);
      tokens.splice(position, 0, {
        type: "meter",
        id: meterId,
        label: getMeterName(meterId),
      });
      handleChange(buildFormula(tokens));
      // Shift placeholder by +1 after insertion
      setInsertPosition(position + 1);
    };

    // Insert an operator token
    const handleAddOperator = (operator, position) => {
      if (!operator) return;
      const tokens = parseFormula(value);
      tokens.splice(position, 0, { type: "operator", value: operator });
      handleChange(buildFormula(tokens));
      setInsertPosition(position + 1);
    };

    // Remove a token by index
    const handleRemoveToken = (index) => {
      const tokens = parseFormula(value);
      if (index < 0 || index >= tokens.length) return;
      tokens.splice(index, 1);
      handleChange(buildFormula(tokens));
      // If removed token was before the placeholder, shift placeholder left
      if (insertPosition !== null && index < insertPosition) {
        setInsertPosition(pos => Math.max(0, pos - 1));
      }
    };

    // Reset formula to initValue and place placeholder at the last position
    const handleReset = () => {
      const newFormula = initValue || "";
      handleChange(newFormula);
      const newTokens = parseFormula(newFormula);
      setInsertPosition(newTokens.length); // <-- Place at end
    };

    // Clear formula entirely and place placeholder at 0
    const handleClearAll = () => {
      handleChange("");
      setInsertPosition(0);
    };

    const tokens = parseFormula(value);
    const isMeterOptionsAvailable = meterOptions.length > 0;

    // Determine if formula changed from initValue
    const isModified = useMemo(() => {
      const strippedInit = (initValue || "").replace(/\s/g, "");
      const strippedVal = (value || "").replace(/\s/g, "");
      return !isEqual(strippedInit, strippedVal);
    }, [initValue, value]);

    // On mount/tokens change, set or clamp placeholder if needed (if still null)
    useEffect(() => {
      if (insertPosition === null) {
        if (tokens.length === 0) {
          setInsertPosition(0);
        } else {
          setInsertPosition(tokens.length);
        }
      } else if (insertPosition > tokens.length) {
        setInsertPosition(tokens.length);
      }
    }, [tokens, insertPosition]);

    // Reposition placeholder when user clicks in display area
    const handleDisplayClick = (e) => {
      // If clicking on a token, do nothing
      if (e.target.closest(".token-item")) {
        return;
      }
      // If empty, position=0
      if (!tokens.length) {
        setInsertPosition(0);
        return;
      }

      const container = containerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const clickY = e.clientY - containerRect.top;
      const clickX = e.clientX - containerRect.left;

      // bounding boxes for each token
      const tokenElements = container.querySelectorAll(".token-item");
      const rects = Array.from(tokenElements).map((el, idx) => {
        const r = el.getBoundingClientRect();
        return {
          index: idx,
          top: r.top - containerRect.top,
          bottom: r.bottom - containerRect.top,
          left: r.left - containerRect.left,
          right: r.right - containerRect.left,
          width: r.width,
          height: r.height,
        };
      });

      // If above first token => 0
      const firstRect = rects[0];
      if (clickY < firstRect.top) {
        setInsertPosition(0);
        return;
      }
      // If below last token => end
      const lastRect = rects[rects.length - 1];
      if (clickY > lastRect.bottom) {
        setInsertPosition(tokens.length);
        return;
      }

      // Identify row
      let rowRects = rects.filter(
        item => clickY >= item.top && clickY < item.bottom
      );
      // If between rows
      if (!rowRects.length) {
        const nextRow = rects.find(r => r.top > clickY);
        if (nextRow) {
          setInsertPosition(nextRow.index);
        } else {
          setInsertPosition(tokens.length);
        }
        return;
      }

      rowRects.sort((a, b) => a.left - b.left);

      // If left of first token in row
      if (clickX < rowRects[0].left) {
        setInsertPosition(rowRects[0].index);
        return;
      }
      // If right of last token in row
      const rowLast = rowRects[rowRects.length - 1];
      if (clickX > rowLast.right) {
        setInsertPosition(rowLast.index + 1);
        return;
      }

      // Otherwise find midpoint
      for (let i = 0; i < rowRects.length; i++) {
        const midX = rowRects[i].left + rowRects[i].width / 2;
        if (clickX < midX) {
          setInsertPosition(rowRects[i].index);
          return;
        }
      }
      setInsertPosition(rowLast.index + 1);
    };

    return (
      <div style={{ height: "100%", boxSizing: "border-box" }}>
        {/* Instructions */}
        <Typography.Paragraph type="secondary" style={{ marginBottom: 8 }}>
          <strong>Usage:</strong> Click a token to remove it. Click the display to move
          the <em>Insert Here</em> placeholder.
        </Typography.Paragraph>

        {/* Meter / Operator Insert UI */}
        <div style={{ marginBottom: 8 }}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Add Meter"
            value={null}
            onSelect={(val) =>
              handleAddMeter(val, insertPosition !== null ? insertPosition : tokens.length)
            }
            options={meterOptions.map(m => ({
              label: m.meter.name,
              value: String(m.meter.id),
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            disabled={!isMeterOptionsAvailable}
          />
          <Button
            style={{ marginLeft: 8 }}
            onClick={() =>
              handleAddOperator("(", insertPosition !== null ? insertPosition : tokens.length)
            }
            disabled={!isMeterOptionsAvailable}
          >
            (
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            onClick={() =>
              handleAddOperator(")", insertPosition !== null ? insertPosition : tokens.length)
            }
            disabled={!isMeterOptionsAvailable}
          >
            )
          </Button>
          {["+", "-", "*", "/"].map(op => {
            const displayOp = getDisplayOperator(op);
            return (
              <Button
                style={{ marginLeft: 8 }}
                key={op}
                onClick={() =>
                  handleAddOperator(op, insertPosition !== null ? insertPosition : tokens.length)
                }
                disabled={!isMeterOptionsAvailable}
              >
                {displayOp}
              </Button>
            );
          })}
        </div>

        {/* Token Display with Insert Placeholder */}
        <div
          ref={containerRef}
          style={{
            minHeight: 80,
            overflowY: "auto",
            border: "1px solid #d9d9d9",
            borderRadius: 6,
            marginBottom: 8,
            padding: 8,
            cursor: "text",
            position: "relative",
          }}
          onClick={handleDisplayClick}
        >
          <div style={{ display: "inline-flex", flexWrap: "wrap", gap: 8 }}>
            {tokens.map((token, idx) => (
              <React.Fragment key={idx}>
                {insertPosition === idx && (
                  <Tag color="blue" style={{ borderStyle: "dashed" }}>
                    Insert Here
                  </Tag>
                )}
                <Tag
                  className="token-item"
                  style={{ cursor: "pointer" }}
                  color={
                    token.type === "meter"
                      ? "#2f54eb"
                      : token.type === "operator"
                        ? "#d46b08"
                        : "#13c2c2"
                  }
                  onClick={() => handleRemoveToken(idx)}
                >
                  {token.label || getDisplayOperator(token.value)}
                </Tag>
              </React.Fragment>
            ))}
            {/* Placeholder at end if tokens exist and position is tokens.length */}
            {tokens.length > 0 && insertPosition === tokens.length && (
              <Tag color="blue" style={{ borderStyle: "dashed" }}>
                Insert Here
              </Tag>
            )}
            {/* If empty formula, placeholder at 0 */}
            {tokens.length === 0 && insertPosition === 0 && (
              <Tag color="blue" style={{ borderStyle: "dashed" }}>
                Insert Here
              </Tag>
            )}
          </div>
        </div>

        {/* Reset / Clear Buttons */}
        <div style={{ marginBottom: 8 }}>
          <Button
            size="small"
            onClick={handleReset}
            disabled={!isMeterOptionsAvailable || !isModified}
          >
            Reset
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            size="small"
            onClick={handleClearAll}
            disabled={!isMeterOptionsAvailable}
            danger
          >
            Clear All
          </Button>
        </div>

        {/* Validation Error (if any) */}
        {errorMessage && (
          <Typography.Text type="danger" style={{ display: "block" }}>
            {errorMessage}
          </Typography.Text>
        )}
      </div>
    );
  }
);

VirtualCalculationBuilder.propTypes = {
  meterOptions: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initValue: PropTypes.string,
};

export default VirtualCalculationBuilder;
