import UrlManager from "../utils/UrlManager";
import "isomorphic-fetch";

class API {
  get(url) {
    return fetch(UrlManager.getAPIUrl() + url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "X-UI-URL": window.location.href,
      },
    }).then(response => {
      if (!response.ok) {
        return response.json().then(errorBody => {
          throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
        });
      }
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        return response.json();
      } else if (contentType && contentType.includes("text/plain")) {
        return response.text();
      } else {
        throw new Error("Warning: Not a JSON or plain text body");
      }
    }).catch(error => {
      let errorObj;
      try {
        errorObj = JSON.parse(error.message);
      } catch (e) {
        errorObj = { code: 500, type: "error", message: error.message };
      }
      return errorObj;
    });
  }
  getWithoutAuthorization(url) {
    return fetch(UrlManager.getAPIUrl() + url, {
      headers: {
        "X-UI-URL": window.location.href,
      },
    }).then(response => {
      if (!response.ok) {
        return response.json().then(errorBody => {
          throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
        });
      }
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        return response.json();
      } else if (contentType && contentType.includes("text/plain")) {
        return response.text();
      } else {
        throw new Error("Warning: Not a JSON or plain text body");
      }
    }).catch(error => {
      let errorObj;
      try {
        errorObj = JSON.parse(error.message);
      } catch (e) {
        errorObj = { code: 500, type: "error", message: error.message };
      }
      return errorObj;
    });
  }
  post(url, data, contentType = "application/json") {
    let formattedData = data;
    if (contentType === "application/json")
      formattedData = JSON.stringify(data);
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "Content-Type": contentType,
        "X-UI-URL": window.location.href,
      },
      body: formattedData,
    })
      .then(function (response) {
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }

        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return { code: 200, type: "success", data: data };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
  postFileUpload(url, formData) {
    return fetch(UrlManager.getProcessorUrl() + url, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "X-UI-URL": window.location.href,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }
        return response.json();
      })
      .then(function (data) {
        return { code: 200, type: "success", data: data };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
  put(url, data) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "PUT",
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "Content-Type": "application/json",
        "X-UI-URL": window.location.href,
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }
        return response.json();
      })
      .then(function (data) {
        return { code: 200, type: "success", data: data };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
  delete(url) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "X-UI-URL": window.location.href,
      },
      timeout: 30000,
    })
      .then(function (response) {
        if (response.ok) {
          return { code: response.status, type: "success", message: "No Content" };
        }
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }
        return response.json();
      })
      .then(function (data) {
        return { code: 200, type: "success", data: data };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
  postPasswordReset(email) {
    return fetch(UrlManager.getAPIUrl() + "user/request-password-reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-UI-URL": window.location.href,
      },
      body: "email=" + email,
    })
      .then(function (response) {
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }
        return response.text();
      })
      .then(function (data) {
        return { code: 200, type: "success", message: data };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
  postLogin(url, email, password) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-UI-URL": window.location.href,
      },
      body: "app=ENGINEERING_UI&username=" + email + "&password=" + password,
    })
      .then(function (response) {
        if (response.status === 200) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        return { code: 200, type: "success", message: data };
      })
      .catch(function (error) {
        return { code: 500, type: "error", message: error };
      });
  }
  postResetLogin(url, user_id, token, password) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-UI-URL": window.location.href,
      },
      body:
        "userId=" +
        user_id +
        "&resetCode=" +
        token +
        "&newPassword=" +
        password,
    })
      .then(function (response) {
        if (response.status === 200) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        return { code: 200, type: "success", message: data };
      })
      .catch(function (error) {
        return { code: 500, type: "error", message: error };
      });
  }
  postInviteLogin(url, user_id, token, password) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-UI-URL": window.location.href,
      },
      body:
        "userId=" +
        user_id +
        "&inviteToken=" +
        token +
        "&newPassword=" +
        password,
    })
      .then(function (response) {
        if (response.status === 200) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        return { code: 200, type: "success", message: data };
      })
      .catch(function (error) {
        return { code: 500, type: "error", message: error };
      });
  }
  getEngineAPI(url) {
    return fetch(UrlManager.getEngineUrl() + url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "X-UI-URL": window.location.href,
      },
      timeout: 30000,
    }).then(
      function (response) {
        if (response.headers.get("Content-Type") !== "application/json") {
          return {
            code: response.status,
            type: "warning",
            message: "Warning: Not a JSON body",
          };
        } else {
          return response.json();
        }
      },
      function (error) {
        return { code: 500, type: "error", message: error };
      }
    );
  }
  postFileDownload(url, data) {
    return fetch(UrlManager.getAPIUrl() + url, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("id_token"),
        "Content-Type": "application/json",
        "X-UI-URL": window.location.href,
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (!response.ok) {
          return response.json().then(errorBody => {
            throw new Error(JSON.stringify({ status: response.status, ...errorBody }));
          });
        }

        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          filename = filenameMatch ? filenameMatch[1] : null;
        }
        // Fallback filename if not found in header
        filename = filename || `export-${new Date().toISOString()}.zip`;

        return response.blob().then(blob => ({ blob, filename }));
      })
      .then(function ({ blob, filename }) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        return { code: 200, type: "success", message: "File downloaded successfully" };
      })
      .catch(function (error) {
        let errorObj;
        try {
          errorObj = JSON.parse(error.message);
        } catch (e) {
          errorObj = { code: 500, type: "error", message: error.message };
        }
        return errorObj;
      });
  }
}

const api = new API();
export default api;
