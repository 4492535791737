import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "./ManageZones.scss";
import { AntTable } from "../../components/AntTable";
import { DocumentTitle } from "../../components/DocumentTitle";
import UserStore from "../../stores/userStore";
import ZoneActions from "../../actions/zonesActions";
import BuildingActions from "../../actions/buildingActions";
import ZoneModal from "./ZoneModal"; // Refactored ModifyZonePanel as ZoneModal
import { Icon } from "../../components/Icon";
//import Constants from "../../constants";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ButtonCell from "../../components/Table/TableCells/ButtonCell/ButtonCell";


/**
 * ManageZones Component
 *
 * Provides an interface for administrators to manage zones within the system.
 */
const ManageZones = () => {
  const [zones, setZones] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState(null);

  /**
   * Fetch all zones when the component mounts or selectedBuilding changes.
   */
  useEffect(() => {
    const fetchZones = async () => {
      try {
        setLoading(true);
        const data = await ZoneActions.v2GetAll();
        setZones(data || []);
      } catch (error) {
        console.error("Error fetching zones:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchZones();
  }, []);

  /**
   * Fetch all buildings when the component mounts.
   */
  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const data = await BuildingActions.getBuildings();
        setBuildings(data || []);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };
    fetchBuildings();
  }, []);

  /**
   * Set super user status on mount.
   */
  useEffect(() => {
    setIsSuperUser(UserStore.isSuper());
  }, []);

  /**
   * Open the ZoneModal.
   */
  const openModal = (zoneItem = null) => {
    setZoneToEdit(zoneItem);
    setModalVisible(true);
  };

  /**
   * Close the ZoneModal.
   */
  const closeModal = () => {
    setModalVisible(false);
    setZoneToEdit(null);
  };

  /**
   * Handle submission from the ZoneModal.
   */
  const handleZoneSubmit = async (payload) => {
    try {
      setLoading(true);
      if (payload.zone_id) {
        // Update existing zone
        const updated = await ZoneActions.v2Update(payload.zone_id, payload);
        setZones((prev) =>
          prev.map((z) => (z.zone.id === updated.zone.id ? updated : z))
        );
      } else {
        // Create new zone
        const created = await ZoneActions.v2Save(payload);
        setZones((prev) => [...prev, created]);
      }
      closeModal();
    } catch (err) {
      console.error("Error saving/updating zone:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle deletion of a zone by opening the confirmation modal.
   */
  const handleDeleteZone = (zone) => {
    setZoneToDelete(zone);
    setIsDeleteModalVisible(true);
  };

  /**
   * Confirm deletion of the selected zone.
   */
  const handleDeleteConfirm = async () => {
    if (!zoneToDelete) return;
    try {
      await ZoneActions.v2Delete(zoneToDelete.zone.id);
      setZones((prev) => prev.filter((z) => z.zone.id !== zoneToDelete.zone.id));
    } catch (error) {
      console.error("Error deleting zone:", error);
    } finally {
      setIsDeleteModalVisible(false);
      setZoneToDelete(null);
    }
  };

  /**
   * Cancel deletion by closing the confirmation modal.
   */
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setZoneToDelete(null);
  };

  /**
   * Define the columns for the AntTable displaying zones.
   */
  const columns = [
    {
      title: "Building",
      dataIndex: ["building", "name"],
      key: "buildingName",
      defaultSortOrder: "ascend",
      filterable: true,
    },
    {
      title: "Name",
      dataIndex: ["zone", "name"],
      key: "name",
      filterable: true,
    },
    {
      title: "Description",
      dataIndex: ["zone", "description"],
      key: "description",
      filterable: true,
    },
    {
      title: "Location",
      dataIndex: ["zone", "location"],
      key: "location",
      filterable: true,
    },
    {
      title: "Actions",
      key: "actions",
      width: 140,
      dontExport: true,
      sorter: false,
      render: (_, record) => (
        <ButtonCell
          wrapper="div"
          key={record.zone.zone_id}
          buttons={[
            {
              label: "Edit Zone",
              icon: "Edit",
              iconColor: "#cc7832",
              clickHandler: () => openModal(record),
            },
            {
              label: "Delete Zone",
              icon: "Cross",
              iconColor: "#d04648",
              clickHandler: () => handleDeleteZone(record),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div className="manage-zones-container br-mainpanel br-profile-page floorplan-background">
      <DocumentTitle title="Manage Zones" />

      <div className="br-container">
        <div className="mg-t-30 tableWrapper">
          {/* AntTable component to display zones */}
          <AntTable
            tableId="ManageZonesTable"
            virtual
            exporting
            columns={columns}
            dataSource={zones}
            loading={loading}
            rowKey={(record) => record?.zone?.id}
            pagination={false}
            scroll={{ x: 1000 }}
            hasInputFilter={true}
            onRow={(record) => ({
              onClick: () => openModal(record),
            })}
            addNewButton={
              <Button className="button green" onClick={() => openModal()}>
                <Icon name="AddCircleFilled" />
                Add Zone
              </Button>
            }
          />
        </div>
      </div>

      {/* ZoneModal component for adding/editing zones */}
      <ZoneModal
        isSuperUser={isSuperUser}
        open={modalVisible}
        onCancel={closeModal}
        onSubmit={handleZoneSubmit}
        zone={zoneToEdit}
        buildings={buildings}
        allZones={zones}
      />

      {/* DeleteConfirmationModal component for confirming deletions */}
      <DeleteConfirmationModal
        open={isDeleteModalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        itemName={zoneToDelete ? zoneToDelete.zone.name : ""}
      />
    </div>
  );
};

export default ManageZones;
