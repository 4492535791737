import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Button, Select, InputNumber, Row, Col, Typography, Flex } from 'antd';
import GeneralUtils from '../../../../utils/GeneralUtils';
import ComponentUtils from '../../../../utils/ComponentUtils';
import netZeroUtils from './NetZeroUtils';

const ConsumptionModal = ({ visible, onClose, consumptionData = [], baseYear, consumptionTypes, saveConsumptionData }) => {
    const [selectedYear, setSelectedYear] = useState(null);
    const [newConsumption, setNewConsumption] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const allYears = Array.from({ length: currentYear - baseYear + 1 }, (_, i) => baseYear + i);
        const sortedConsumptionData = consumptionData.map(item => {
            const type = consumptionTypes.find(t => t.category === item.category);

            // Add missing years with default values
            const filledData = allYears.map(year => {
                const yearData = item.data.find(d => d.year === year);
                if (yearData) {
                    return {
                        ...yearData,
                        mode: 'annual',
                        annualValue: netZeroUtils.sum(yearData.values)
                    };
                } else {
                    return {
                        year,
                        mode: 'annual',
                        values: netZeroUtils.allMonthsNull(),
                        annualValue: 0
                    };
                }
            });

            return {
                ...item,
                scope: type ? type.scope : Infinity,
                data: filledData
            };
        }).sort((a, b) => {
            if (a.scope === 1 && b.scope !== 1) return -1;
            if (a.scope !== 1 && b.scope === 1) return 1;
            return (a.scope + a.description) - (b.scope + b.description);
        });

        setNewConsumption(sortedConsumptionData);
    }, [consumptionData, consumptionTypes, baseYear]);

    const availableYears = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = baseYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years.reverse();
    }, [baseYear]);

    useEffect(() => {
        if (visible && availableYears.length > 0) {
            consumptionData.length === 0 ?
            setSelectedYear(availableYears[availableYears.length - 1]) :
            setSelectedYear(availableYears[0]);
        }
    }, [visible, availableYears, consumptionData]);

    const availableEnergyTypes = useMemo(() => {
        const activeTypes = new Set(newConsumption.map(item => item.category));
        return consumptionTypes.filter(type => !activeTypes.has(type.category));
    }, [consumptionTypes, newConsumption]);

    const activeEnergyTypes = useMemo(() => {
        return [...new Set(newConsumption.map(item => item.category))]
            .map(ref => consumptionTypes.find(t => t.category === ref))
            .filter(Boolean);
    }, [newConsumption, consumptionTypes]);

    const handleTypeChange = (remainingTypes) => {
        const newConsumptionTypes = newConsumption.map(item => item.category);

        if (remainingTypes.length > newConsumptionTypes.length) {
            // add type
            let updatedConsumption = [...newConsumption];
            remainingTypes.forEach(type => {
                if (!newConsumptionTypes.includes(type)) {
                    const matchingType = consumptionTypes.find(t => t.category === type);
                    updatedConsumption.push({
                        category: type,
                        data: availableYears.map(year => ({
                            year,
                            mode: 'annual',
                            values: netZeroUtils.allMonthsNull()
                        })),
                        scope: matchingType.scope
                    });
                }
            });
            setNewConsumption(updatedConsumption);
        } else {
            // remove type
            Modal.confirm({
                title: 'Confirm Removal',
                content: 'Removing this type will result in the loss of all associated consumption data. Do you wish to proceed?',
                onOk() {
                    const updatedConsumption = newConsumption.filter(item => remainingTypes.includes(item.category));
                    setNewConsumption(updatedConsumption);
                }
            });
        }
    };

    const handleInputChange = (category, monthIndex, value) => {

        const updatedConsumption = newConsumption.map(item => {
            if (item.category === category) {
                let yearData = item.data.find(d => d.year === selectedYear);

                if (!yearData) {
                    // Create a new year entry with all values set to null
                    yearData = { year: selectedYear, values: netZeroUtils.allMonthsNull() };
                    item.data.push(yearData);
                }

                yearData.values[monthIndex] = value;
            }
            return item;
        });
        setNewConsumption(updatedConsumption);
    };

    const handleOk = async () => {

        // check if the selected types have at least one non-null value in at least one year and filter the all-null years
        const newConsumptionObject = newConsumption
            .filter(con => {
                return con.data.some(year => year.values.some(value => value !== null));
            })
            .map(con => {
                return {
                    ...con,
                    data: con.data.filter(year => year.values.some(value => value !== null))
                }
            })

        await saveConsumptionData(newConsumptionObject);
        onClose();
    };

    const clearCategoryValues = (category) => {
        const updatedConsumption = newConsumption.map(item => {
            if (item.category === category) {
                let yearData = item.data.find(d => d.year === selectedYear);

                if (yearData) {
                    yearData.values = netZeroUtils.allMonthsNull();
                    yearData.annualValue = null;
                }
            }
            return item;
        });
        setNewConsumption(updatedConsumption);
    };

    const toggleMode = (category, year) => {
        setNewConsumption(prevConsumption => prevConsumption.map(item => {
            if (item.category === category) {
                return {
                    ...item,
                    data: item.data.map(yearData => {
                        if (yearData.year === year) {
                            if (yearData?.mode === 'annual') {
                                // Switching to monthly: update monthly values if annualValue is non-null
                                if (yearData.annualValue != null) {
                                    yearData.values = netZeroUtils.distributeOverMonths(yearData.annualValue);
                                }
                            } else {
                                // Switching to annual: calculate annual value if monthly values are non-null
                                const allNull = netZeroUtils.isAllNull(yearData.values);
                                yearData.annualValue = allNull ? null : netZeroUtils.sum(yearData.values);
                            }
                            return {
                                ...yearData,
                                mode: yearData?.mode === 'monthly' ? 'annual' : 'monthly'
                            };
                        }
                        return yearData;
                    })
                };
            }
            return item;
        }));
    };

    const renderMonthInputs = (category, yearData) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const total = yearData ? netZeroUtils.sum(yearData.values) : 0;
        return (
            <Row gutter={20}>
                <Col span={20}>
                    <Row gutter={20} className='mg-b-10'>
                        {months.slice(0, 6).map((month, index) => (
                            <Col span={4} key={`${category}_${month}`}>
                                <Typography.Text>{month}</Typography.Text>
                                <InputNumber
                                    min={0}
                                    value={yearData ? yearData.values[index] : null}
                                    onChange={(value) => handleInputChange(category, index, value)}
                                    style={{ width: '100%' }}
                                    controls={false}
                                    onFocus={(e) => e.target.select()}
                                    precision={0}
                                    formatter={GeneralUtils.getFormattedNumber}
                                />
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={20}>
                        {months.slice(6, 12).map((month, index) => (
                            <Col span={4} key={`${category}_${month}`}>
                                <Typography.Text>{month}</Typography.Text>
                                <InputNumber
                                    min={0}
                                    value={yearData ? yearData.values[index + 6] : null}
                                    onChange={(value) => handleInputChange(category, index + 6, value)}
                                    style={{ width: '100%' }}
                                    controls={false}
                                    onFocus={(e) => e.target.select()}
                                    precision={0}
                                    formatter={GeneralUtils.getFormattedNumber}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography.Title level={5} style={{ textAlign: 'center' }}>
                        Total Consumption
                    </Typography.Title>
                    <Typography.Text>
                        {GeneralUtils.getFormattedNumberWithUnit(total, ' kWh', 0)}
                    </Typography.Text>
                </Col>
            </Row>
        );
    };

    const renderAnnualInput = (category, yearData) => {
        const annualValue = yearData.annualValue || null;
        return (
            <Row gutter={20}>
                <Col span={6}>
                    <Typography.Text>Total Annual Consumption</Typography.Text>
                    <InputNumber
                        min={0}
                        value={annualValue}
                        formatter={GeneralUtils.getFormattedNumber}
                        onChange={(value) => {
                            setNewConsumption(prevConsumption => prevConsumption.map(item => {
                                if (item.category === category) {
                                    return {
                                        ...item,
                                        data: item.data.map(data => {
                                            if (data.year === yearData.year) {
                                                return {
                                                    ...data,
                                                    annualValue: value,
                                                    values: netZeroUtils.distributeOverMonths(value)
                                                };
                                            }
                                            return data;
                                        })
                                    };
                                }
                                return item;
                            }));
                        }}
                        style={{ width: '100%' }}
                        controls={false}
                        onFocus={(e) => e.target.select()}
                        precision={0}
                    />
                </Col>
            </Row>
        );
    };

    const renderEnergyTypesSelect = () => {

        const groups = [...new Set(availableEnergyTypes.map(t => t.group_description).sort((a, b) => a.localeCompare(b)))]
        const groupedOptions = groups.map(group => {
            return {
                label: group,
                title: group,
                options: ComponentUtils.generateSelectOptions(
                    availableEnergyTypes.filter(t => t.group_description === group),
                    t => t.category,
                    t => `${t.description} (Scope ${t.scope})`
                )
            }
        })

        return <Select
            mode="multiple"
            value={ComponentUtils.generateSelectOptions(
                activeEnergyTypes,
                type => type.category,
                type => type.description
            )}
            onChange={handleTypeChange}
            style={{ width: 400 }}
            placeholder="Select types to add/remove"
            options={groupedOptions}
        >
        </Select>
    }

    return (
        <Modal
            open={visible}
            title="Add Consumption Data"
            onCancel={onClose}
            width={1000}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
            <Flex justify="space-between" align="center" wrap="wrap" style={{ marginBottom: '20px', gap: '10px' }}>
                <Flex gap="small" align="center">
                    <Typography.Text>Year:</Typography.Text>
                    <Select
                        value={selectedYear}
                        onChange={setSelectedYear}
                        style={{ width: 170 }}
                    >
                        {availableYears.map((year, index) => (
                            <Select.Option key={year} value={year}>
                                {index === availableYears.length - 1 ? `${year} - Base Year` : year}
                            </Select.Option>
                        ))}
                    </Select>
                </Flex>

                <Flex gap="small" align="center">
                    <Typography.Text>Energy Types:</Typography.Text>
                    {renderEnergyTypesSelect()}
                </Flex>
            </Flex>
            {selectedYear && newConsumption
                .map(item => {
                    const yearData = item.data.find(d => d.year === selectedYear);
                    const category = consumptionTypes.find(t => t.category === item.category);
                    const currentMode = yearData?.mode || 'annual';
                    return (
                        <div key={item.category} className='mg-b-40' style={{ border: '1px solid #e0e0e0', background: '#eee', padding: '20px', borderRadius: '12px' }}>
                            <Flex justify="space-between" align="center">
                                <Typography.Title level={5}>
                                    {category?.description} - Scope {category?.scope}
                                </Typography.Title>
                                <Flex gap="small">
                                    <Button size="small" onClick={() => toggleMode(item.category, selectedYear)}>
                                        {currentMode === 'monthly' ? 'Switch to Annual' : 'Switch to Monthly'}
                                    </Button>
                                    <Button size="small" onClick={() => clearCategoryValues(item.category)}>Clear</Button>
                                </Flex>
                            </Flex>
                            {currentMode === 'monthly' ? renderMonthInputs(item.category, yearData) : renderAnnualInput(item.category, yearData)}
                        </div>
                    );
                })}
        </Modal>
    );
};
export default ConsumptionModal; 