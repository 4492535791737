import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AntTable from '../../../../components/AntTable/AntTable';
import { Typography } from 'antd';
import GeneralUtils from '../../../../utils/GeneralUtils';

const ConsumptionTable = ({ consumptionData, consumptionTypes }) => {

    const availableYears = useMemo(() => {
        const yearsWithData = new Set(consumptionData.flatMap(item => item.data.map(d => d.year)));
        return Array.from(yearsWithData).sort((a, b) => b - a); // Sort in descending order
    }, [consumptionData]);

    // Modified tableData transformation
    const tableData = useMemo(() => {
        const activeEnergyTypes = [...new Set(consumptionData.map(item => item.category))];

        return activeEnergyTypes.flatMap(energyTypeRef => {
            const consumption = consumptionData.find(item => item.category === energyTypeRef);
            const type = consumptionTypes.find(t => t.category === energyTypeRef);

            return availableYears.map(year => {
                const yearData = consumption?.data.find(d => d.year === year);
                
                // Skip this row if no data exists for this year
                if (!yearData) return null;

                const annualValue = yearData.values.reduce((acc, val) => acc + (val || 0), 0);

                return {
                    key: `${energyTypeRef}-${year}`,
                    category: energyTypeRef,
                    description: type?.description,
                    scope: type?.scope,
                    year: year,
                    annualValue: annualValue,
                    monthlyValues: yearData.values
                };
            });
        })
        .filter(item => item !== null) // Remove null entries
        .sort((a, b) => (a.scope || 0) - (b.scope || 0)); // Sort by scope
    }, [consumptionData, availableYears, consumptionTypes]);


    const columns = [
        {
            title: 'Energy Type',
            dataIndex: 'description',
            fixed: 'left',
            width: 200,
            filterable: true,
            render: (description, record) => `${description} (Scope ${record.scope})`
        },
        {
            title: 'Annual Value (kWh)',
            dataIndex: 'annualValue',
            width: 200,
            render: (value) => {
                if (value) return <span>{GeneralUtils.getFormattedNumberWithUnit(value)}</span>;
                return value;
            }
        },
        {
            title: 'Monthly Values (kWh)',
            dataIndex: 'monthlyValues',
            render: (monthlyValues) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {monthlyValues.map((value, index) => (
                        <div key={index} style={{ width: '16.66%', textAlign: 'center', marginBottom: '10px' }}>
                            <div style={{ opacity: 0.8, fontWeight: 'bold' }}>
                                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][index]}
                            </div>
                            <div>
                                {value ? GeneralUtils.getFormattedNumberWithUnit(value) : 'N/A'}
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    ];

    const placeholder = <div style={{ height: '635px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography.Title level={4}>No data available</Typography.Title>
        <Typography.Text>Please add consumption data.</Typography.Text>
    </div>

    return (
        <div style={{ overflow: 'auto', height: '700px' }}>
            {availableYears.map(year => {
                const yearTableData = tableData.filter(item => item.year === year);
                return (
                    <div key={year} style={{ marginBottom: '20px' }}>
                        <Typography.Title level={4}>{year}</Typography.Title>
                        <AntTable
                            tableId={`consumption-data-${year}`}
                            columns={columns}
                            dataSource={yearTableData}
                            scroll={{ y: 300 }}
                        />
                    </div>
                );
            })}
            {availableYears.length === 0 && placeholder}
        </div>
    );
};

ConsumptionTable.propTypes = {
    consumptionTypes: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string.isRequired,
        scope: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired
    })).isRequired,
    consumptionData: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({
            year: PropTypes.number.isRequired,
            values: PropTypes.arrayOf(PropTypes.number).isRequired
        })).isRequired
    })).isRequired
};

export default ConsumptionTable; 