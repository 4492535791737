import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Statistic, Typography } from 'antd';
import AntTable from '../../../../components/AntTable/AntTable';
import ButtonCell from '../../../../components/Table/TableCells/ButtonCell/ButtonCell';
import generalUtils from '../../../../utils/GeneralUtils';

const ActionsTable = ({ actions, onEditAction, onDeleteAction }) => {

    const newActions = useMemo(() => {
        return actions.map(action => {
            let annual_impact = null;

            if (action.impacts) {
                annual_impact = action.impacts.reduce((sum, impact) => sum + impact.energy_impact.reduce((sum, val) => sum + val, 0), 0);
            }

            return {
                ...action,
                annual_impact: annual_impact
            };
        });
    }, [actions]);

    const actionColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            filterable: true,
            width: '20%',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            filterable: true,
            width: '20%',
            render: (category) => generalUtils.defaultValueToDisplayText(category)
        },
        {
            title: 'Annual Impact',
            dataIndex: 'annual_impact',
            width: '30%',
            render: (_, record) => {
                const impacts = record.impacts;

                if (!impacts || !impacts.length) return null;

                return impacts.map((impact, index) => {
                    // Calculate total reduction for this impact
                    const total = impact.energy_impact
                        .filter(val => val !== null)
                        .reduce((sum, val) => sum + val, 0);

                    return (
                        <div key={index} style={{ marginBottom: index < impacts.length - 1 ? '8px' : 0 }}>
                            <Statistic
                                title={<span style={{ fontSize: '12px' }}>{impact.description}</span>}
                                value={generalUtils.getFormattedNumberWithUnit(total, ' kWh')}
                                valueStyle={{
                                    fontSize: '14px',
                                    color: total < 0 ? '#3f8600' : '#cf1322'
                                }}
                            />
                        </div>
                    );
                });
            }
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            filterable: true,
            width: '10%',
            render: (cost) => generalUtils.getFormattedNumber(cost)
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            filterable: true,
            width: '10%',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: '10%',
            sorter: false,
            render: (_, record) => {
                if (record.isSum || record.isIntermediateSum || record.baseline) return null;
                return (
                    <ButtonCell
                        wrapper="div"
                        buttons={[
                            {
                                label: 'Edit Action',
                                icon: 'Edit',
                                iconColor: '#cc7832',
                                clickHandler: () => onEditAction(record)
                            },
                            {
                                label: 'Delete Action',
                                icon: 'Delete',
                                iconColor: '#d04648',
                                clickHandler: () => onDeleteAction(record)
                            }
                        ]}
                    />
                );
            }
        }
    ];

    const placeholder = <div style={{ height: '635px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography.Title level={4}>No actions available.</Typography.Title>
        <Typography.Text>Actions for this plan will appear here.</Typography.Text>
    </div>

    return newActions.length ? (
        <AntTable
            tableId="net-zero-actions"
            columns={actionColumns}
            dataSource={newActions}
            pagination={false}
            rowKey={'id'}
        />
    ) : placeholder;
};

ActionsTable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        y: PropTypes.number,
        isSum: PropTypes.bool,
        isIntermediateSum: PropTypes.bool,
        baseline: PropTypes.bool
    })).isRequired,
    onEditAction: PropTypes.func.isRequired,
    onDeleteAction: PropTypes.func.isRequired,
};

export default ActionsTable;
